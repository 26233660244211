
import { faBook, faHouse, faFilePen, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SidebarData = [
    {
        title: "Dashboard",
        icon: <FontAwesomeIcon icon={ faHouse } />,
        link: "/dashboard"
    },
    {
        title: "Courses",
        icon: <FontAwesomeIcon icon={faBook} />,
        link: "/courses"
    },

]


const TeacherNavLinks = [
    {
        title: "Gradebook",
        icon: <FontAwesomeIcon icon={faFilePen} />,
        link: "gradebook"
    },
    {
        title: "AI Tools",
        icon: <FontAwesomeIcon icon={faRobot} />,
        link: "ai-tools"
    }
]

export { SidebarData, TeacherNavLinks}