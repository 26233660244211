import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AIQuizResults from "./Results/AIQuizResults";
import { faArrowLeft, faArrowRotateRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import AILessonPlanResults from "./Results/AILessonPlanResults";
import AIPythonActivityResults from "./Results/AIPythonActivityResults";
import AILessonArticleResults from "./Results/AILessonArticleResults";
import FileSaver from "file-saver";


export default function AIToolResult({results, title, toolType, handleReset, handleRegenerate})
{
    function getResultsComponent()
    {
        if (toolType === "Quiz")
        {
            return <AIQuizResults results={results} />
        }
        else if (toolType === "LessonPlan") {
            return <AILessonPlanResults results={results} />
        }
        else if (toolType === "PythonActivity") {
            console.log(results);
            return <AIPythonActivityResults results={results} />
        }
        else if (toolType === "LessonArticle") {
            console.log(results);
            return <AILessonArticleResults results={results} />
        }
    }

    function handleExport()
    {
        var resultsBlob = new Blob([JSON.stringify(results)], {type: "text/plain;charset=utf-8"});
        var curTimeSeconds = new Date().getTime() / 1000;
        FileSaver.saveAs(resultsBlob, `spatialgrow_${toolType}_results_${Math.floor(curTimeSeconds)}.json`);
    }

    return (
        <>
        <div className="c-page-container ai-result-header">
            <div className="ai-result-header-side">
                <div className="ai-result-header-button" onClick={handleReset}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <p>New Prompt</p>
                </div>
            </div>
                
            <h3 className="ai-result-header-title">{title + " Result"}</h3>
            <div className="ai-result-header-side">
                <div className="ai-result-header-button" onClick={handleRegenerate}>
                    <FontAwesomeIcon className="ai-result-header-button-icon" icon={faArrowRotateRight}/>
                    <p>Regenerate</p>
                </div>
                <div className="ai-result-header-button" onClick={handleExport}>
                    <FontAwesomeIcon className="ai-result-header-button-icon" icon={faDownload}/>
                    <p>Export</p>
                </div>
            </div>
        </div>
        <div>
            {getResultsComponent()}
        </div>
        </>
    );
}