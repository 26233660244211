import { Link } from "react-router-dom";
import Logo from "../assets/img/PAT-2022-with-leaf.png";

export default function NotFound() {
    return (
        <div className="page-not-found">
            <h1>Page not found!</h1>
            <img className="page-not-found-img" src={Logo} />
            <p>Go back to the <Link to={"/dashboard"}>Dashboard</Link></p>

        </div>
    )
}