import { LearningObjectives, LessonDetails } from "../../pages/Course/LessonParts";

export default function LessonWithSlides({lessonDetails, slidesSrc})
{

    function createLessonContent(content)
    {
        if (content.external_content.length > 0)
        {
            const splitText = content.text.split("external_content");

            const generatedContent = [<span key={"0101"}>{splitText[0]}</span>];
            for (let i = 1; i < splitText.length; i++)
            {
                // Get the current content
                const arrIdx = Number(splitText[i].substring(0, 3)[1]);
                const curEl = content.external_content[arrIdx];

                if (curEl.type === "website")
                {
                    generatedContent.push(
                        <>
                            <a href={curEl.file} target="_blank" key={curEl.name}>{curEl.name}</a>
                            <span key={arrIdx}>{splitText[i].substring(3)}</span>
                        </>
                    );
                }
            }
            return generatedContent;
        }
        else {
            return content.text;
        }
    }

    return (
       <>
            <div className="lesson-content">
                <LearningObjectives 
                    learningOutcomes={lessonDetails.learning_outcomes}
                    standards={lessonDetails.standards} />
                <LessonDetails 
                    summary={lessonDetails.summary}
                    content={ createLessonContent(lessonDetails.content) } />
            </div>
            {lessonDetails.slides && 
            <div className="google-slides-container-background">
                <div className="google-slides-container">
                    <iframe 
                        src={slidesSrc}
                        frameBorder="0"
                        width="100%"
                        height="0"
                        allowFullScreen={true}
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true"></iframe>
                </div>
            </div>
            }
       </>
       
    );
}