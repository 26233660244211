import { useMemo } from "react";
import { LearningObjectives } from "../../pages/Course/LessonParts";
import parse from 'html-react-parser';
import { formatCodeText } from "../../assets/js/utils";

export default function LessonArticle({lessonDetails}) {

    const renderedArticle = useMemo(() => {
        // Get the sections
        const sections = lessonDetails.content.sections;
        
        // Contains the JSX with the article sections
        const articleJSX = [];

        // Iterate over all the sections
        for (let i = 0; i < sections.length; i++)
        {
            // Contains the JSX for the current section
            const curSectionJSX = [];

            // Create the header
            curSectionJSX.push(
                <h2 className="lesson-article-section-header" key={`section_header_${i}`}>{sections[i].header}</h2>
            );

            // Create the paragraphs
            for (let j = 0; j < sections[i].paragraphs.length; j++)
            {
                const curPar = sections[i].paragraphs[j];

                if (typeof curPar === 'string' || curPar instanceof String)
                {
                    // It is a string, so write as is
                    curSectionJSX.push(
                        <p className="lesson-article-section-paragraph" key={`p_${i}_${j}`}>{formatCodeText(curPar, sections[i].code_snippet)}</p>
                    );
                } else {
                    // Check if it has text before the formatted parts
                    if (Object.hasOwn(curPar, "header")) {                    
                        curSectionJSX.push(
                            <p className="lesson-article-section-paragraph" key={`p_${i}_${j}`}>{parse(curPar.header)}</p>
                        );
                    }

                    // Create the ul element
                    if (Object.hasOwn(curPar, "ul"))
                    {
                    
                        curSectionJSX.push(
                            <ul className="lesson-article-section-paragraph" key={`ul_${i}_${j}`}>
                                {
                                    curPar.ul.map((item, idx) => <li key={`li_${i}_${j}_${idx}`}>{parse(item)}</li>)
                                }
                            </ul>
                        );
                    }
                }   
            }

            // Add the section
            articleJSX.push(
                <div className="lesson-article-section" key={`section_${i}`}>
                    {curSectionJSX}
                </div>
            );
        }

        return <div className="lesson-articles-sections">{articleJSX}</div>;

    }, [lessonDetails]);

    return (
        <div className="lesson-article">
            <h1 className="lesson-article-title">{lessonDetails.name}</h1>
            { lessonDetails.learning_outcomes && lessonDetails.standards &&
            <LearningObjectives 
                    learningOutcomes={lessonDetails.learning_outcomes}
                    standards={lessonDetails.standards} />
            
            }
            {renderedArticle}

        </div>
    );
}