import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { firestoreDb } from "../../assets/js/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { LearningObjectives, LessonDetails } from "./LessonParts";
import { useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";


export default function Unit() {
    const { classID, courseID, unitID } = useParams();
    const [unitDetails, setUnitDetails ] = useState(""); 
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);

    useEffect(()=> {
            const fetchData = async() => {
                try{
                    const storedUser = JSON.parse(localStorage.getItem("user"));
                    const unitRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}`);
                    const unitSnap = await getDoc(unitRef);
                    const unitData = unitSnap.data();

                    // Get the class settings
                    if (storedUser.user_type === "student")
                    {
                        const classSettingsRef = doc(firestoreDb, `ClassSettings/${classID}`);
                        const classSettingsDoc = await getDoc(classSettingsRef);
                        const classSettingsData = classSettingsDoc.data();
                        unitData.activeLessons = classSettingsData.active_lessons.find(u => {
                            return u.unit_id === unitSnap.id;
                        });
                    }
                        
                    setUnitDetails(unitData);
                    setPageHeader(unitData.name);
                } catch (error)
                {
                    console.error(error);
                }
            }
            
            fetchData();
        
        
    }, []);
    return (
        <div className="c-page-container lesson-page">
            {unitDetails && 
            <div>
                <div className="lesson-content">
                    <LearningObjectives 
                            learningOutcomes={unitDetails.learning_outcomes}
                            standards={""} />
                    <LessonDetails 
                        summary={unitDetails.summary}
                        content={""} />
                </div>
                <div>
                    <h3>Lessons</h3>
                    <ul className="unit-page-lessons">
                        {
                            unitDetails.lesson_order.map((lesson, lessonIdx) => {
                                return (
                                    <li className="lesson-name-link" key={lessonIdx}>
                                        {!unitDetails.activeLessons || unitDetails.activeLessons.lessons[lessonIdx].enabled ?
                                            <Link to={`/courses/${classID}/${courseID}/${unitID}/${lesson.lesson_id}`}>{lesson.lesson_name}</Link>
                                        :
                                        <span className="disabled-link">{lesson.lesson_name}</span>   
                                        }
                                        
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
           }
        </div>
    )
}