import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyCA78w0M_cvysOH5ni1EoD03HiofTcwcuU",
  authDomain: "spatialgrowclassroom.firebaseapp.com",
  projectId: "spatialgrowclassroom",
  storageBucket: "spatialgrowclassroom.appspot.com",
  messagingSenderId: "793068620013",
  appId: "1:793068620013:web:39f979af099102bcdd24e4",
  measurementId: "G-S4B9GKENEL"
};

// const unregisterAuthObserver = auth().onAuthStateChanged(user =>
//   setAuthState({ user, pending: false, isSignedIn: !!user })
// );

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestoreDb = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

const firebaseSubmitQuiz =  httpsCallable(functions, "submitQuiz");
const firebaseRegisterForCourse = httpsCallable(functions, "registerForCourse");
const firebaseAddStudentToCourse = httpsCallable(functions, "addStudentToCourse");
const firebaseGetAssignmentGrade = httpsCallable(functions, "getAssignmentGrade");
const firebaseValidateSubscriptionCode = httpsCallable(functions, "validateSubscriptionCode");
const firebaseRegisterUser = httpsCallable(functions, "registerUser");
const firebaseVerifySubscription = httpsCallable(functions, "verifyActiveSubscription");


try {
  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_EMULATORS === "true")
  {
      connectFirestoreEmulator(firestoreDb, "127.0.0.1", 8080);
      connectAuthEmulator(auth, "http://127.0.0.1:9099");
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      console.log("USING EMULATORS", process.env.REACT_APP_USE_EMULATORS);
  }
} catch (error)
{
  console.log(error);
}



function getCurrentUser(auth) {
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      resolve(auth.currentUser);
    }
    const unsubscribe = auth.onAuthStateChanged(user => {
      console.log("onAuthStateChanged");
      console.log(user);
      unsubscribe();
      resolve(user);
     }, reject);
  });
}

/**
 * Get the access token for the user currently signed in.
 * @return {string} - The refreshed access token.
*/
async function getAccessToken()
{
  const user = JSON.parse(localStorage.getItem("user"));
  const currentUser = await getCurrentUser(auth);
  console.log("CURRENT USER: ");
  console.log(currentUser);
  console.log(auth.currentUser);
  const accessToken = await getCurrentUser(auth).getIdToken(true).then((idToken) => {
    user.stsTokenManager.accessToken = idToken;
    localStorage.setItem("user", JSON.stringify(user));
    return idToken;
  });
  return accessToken;
}


export {  
  auth,
  firestoreDb,
  storage,
  analytics,
  getAccessToken,
  firebaseSubmitQuiz,
  firebaseRegisterForCourse,
  firebaseAddStudentToCourse,
  firebaseGetAssignmentGrade,
  firebaseValidateSubscriptionCode,
  firebaseRegisterUser,
  firebaseVerifySubscription,
};
