import parse from "html-react-parser";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function formatGrade(grade)
{
    // Round the grade
    const roundedGrade = Math.round((grade + Number.EPSILON) * 100) / 100;
    let formattedGrade = roundedGrade.toFixed(2); 
    if (roundedGrade.toString().split(".").length === 2)
    {
        return formattedGrade;
    } else {
        return roundedGrade;
    }
}

function createInlineCodeSnippets(text, idx)
{
    const descWithInlineCode = []; 

    // Split by the <code>*</code> snippets
    const inlineCodeArr = text.split(/\<code\?(class=\"inline-code\")\>(.*?)\<\/code\>/);
    
    // Iterate over and create them
    for (let i = 0; i < inlineCodeArr.length; i++)
    {
        if (i % 2 === 0)
        {
            // Even numbers only contain text so add it as is
            descWithInlineCode.push(
                <span key={`desc_${idx}_${i}`}>
                    {parse(inlineCodeArr[i])}
                </span>
            );
        }
        else {
            // Odd numbers contain the inline code snippet
            descWithInlineCode.push(
                <code className="inline-code" key={`inline_code_${idx}_${i}`}>
                    {inlineCodeArr[i].trim()}
                </code>
            );
        }
    }

    return descWithInlineCode;
}

function formatCodeText(desc, codeSnippets)
{
 
    const splitDesc = desc.split("code_snippet");
    if (splitDesc.length === 0)
    {
        return desc;
    }

    const generatedContent = createInlineCodeSnippets(splitDesc[0], 0);

    for (let i = 1; i < splitDesc.length; i++)
    {
        // Get the index value of the code snippet
        const matchedTxt = splitDesc[i].split(/\[(.*?)\]/);
        const idxVal = matchedTxt[1];

        // Check if this code snippet is actually just text
        if (codeSnippets[idxVal].includes("<text>"))
        {
            // Add the text snippet          
            generatedContent.push(
                <SyntaxHighlighter className="code-snippet" language="plaintext" style={tomorrowNight} key={`code_snippet_${i}`}>
                    {codeSnippets[idxVal].split(/\<text\>([\s\S]*?)\<\/text\>/)[1]}
                </SyntaxHighlighter>
            );
        } else {
            // Add the code snippet          
            generatedContent.push(
                <SyntaxHighlighter className="code-snippet" language="python" style={tomorrowNight} key={`code_snippet_${i}`}>
                    {codeSnippets[idxVal]}
                </SyntaxHighlighter>
            );
        }

        // Add the text after the code snippet
        if (matchedTxt[2].trim().length > 0)
        {
            generatedContent.push(
                ...createInlineCodeSnippets(matchedTxt[2], i)
            )   
        }
    }

    return generatedContent;
}

export { formatGrade, createInlineCodeSnippets, formatCodeText };