
// Import Logo
import Logo from '../assets/img/favicon.png';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from '../assets/js/firebase-config';
import { signInWithEmailAndPassword } from "firebase/auth";
import SignUpModal from '../components/modals/SignUpModal';
import ErrorMessage from "../components/messages/ErrorMessage";
import ResetPasswordModal from '../components/modals/ResetPasswordModal';

export default function Login({handleLogin}) {
    const navigate = useNavigate()
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    async function handleSubmitLogin(e) {
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        const result = await signInWithEmailAndPassword(auth, formJson.email, formJson.password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            const verifyResult = await handleLogin(user);
            if (verifyResult.status === 400)
            {
                return verifyResult;
            }

            navigate("/dashboard");
            return {status: 200};
        })
        .catch((error) => {
            const result = {
                status: 400,
                error_msg: error.message
            };
            console.error(error);
            return result;
        });

        if (result.status === 400)
        {
            console.log(result);
            if (result.error_msg.includes("user-not-found"))
            {
                setErrorMessage("Couldn't find an account with that email");
            }
            else if (result.error_msg.includes("invalid-email"))
            {
                setErrorMessage("Please enter a valid email address");
            }
            else if (result.error_msg.includes("wrong-password"))
            {
                setErrorMessage("Incorrect email or password.")
            }
            else if (result.error_msg.includes("too-many-requests"))
            {
                setErrorMessage("Too failed login attempts. Account has been temporarily disabled.");
            }
            else if (result.error_msg.includes("subscription"))
            {
                setErrorMessage(result.error_msg);
            }
            else {
                setErrorMessage("Something went wrong... Please try again")
            }
        }
    }

    return (
        <>
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
                <div className="container">
                    <div className="loginbox">
                        <div className="login-left">
                            <img className="img-fluid" src={Logo} alt="Logo" />
                        </div>

                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Login</h1>
                                <p className="account-subtitle">Access to our dashboard</p>
                                
                                <form onSubmit={handleSubmitLogin}>
                                    <div className="form-group">
                                        <input className="form-control" type="text" placeholder="Email" name="email" />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" type="password" placeholder="Password" name="password"/>
                                    </div>
                                    
                                    {errorMessage && 
                                    <div>
                                        <ErrorMessage msg={errorMessage} />
                                    </div>
                                    }
                                    
                                    <div className="form-group">
                                        
                                        <button className="c-button" type="submit">Login</button>
                                        
                                        {/* <Link className="btn btn-primary btn-block" to="dashboard">Login</Link> */}
                                        {/* <a href="/react/dashboard" className="btn btn-primary btn-block">Login</a> */}
                                    </div>
                                </form>
                                
                                <div className="text-center forgotpass"><a onClick={() => {setShowResetPasswordModal(true)}}>Forgot Password?</a></div>
                                <div className="login-or">
                                    <span className="or-line"></span>
                                    <span className="span-or">or</span>
                                </div>                                
                                
                                {/* <div className="social-login">
                                    <span>Login with</span>
                                    <a href="#" className="facebook"><FontAwesomeIcon icon={faFacebookF} /></a><a href="#" className="google"><FontAwesomeIcon icon={faGoogle} /></a>
                                </div>
                                 */}
                                <div className="text-center dont-have">Don’t have an account? <a style={{cursor: "pointer"}} onClick={() => {setShowSignUpModal(true)}}>Register</a></div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        { showSignUpModal &&
            <SignUpModal setShowSignUpModal={setShowSignUpModal} handleLogin={handleLogin}/>
        }
        { showResetPasswordModal &&
            <ResetPasswordModal setShowModal={setShowResetPasswordModal} />
        }
        </>
    )
}
