import { useContext, useEffect, useState } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { useParams } from "react-router-dom";
import AIToolForm from "../../components/ai/AIToolForm";
import { AIToolsMetadata } from "../../components/ai/AIToolsData";
import AIToolResult from "../../components/ai/AIToolResult";
import { Oval } from "react-loader-spinner";

export default function AITool()
{
    const { curPageHeader, setPageHeader } = useContext(PageHeaderContext);
    const { aiTool } =  useParams();
    const [ aiToolMetadata, setAIToolMetadata ] = useState(null);
    const [dataResponse, setDataResponse] = useState(null);
    const [requestBody, setRequestBody] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // TODO: Get the input
        const foundAIToolMetadata = AIToolsMetadata.find(el => el.link === aiTool);
        setAIToolMetadata(foundAIToolMetadata);
        setPageHeader(foundAIToolMetadata.title);
    }, [])

    function submitAIToolForm(e)
    {
        e.preventDefault();
        e.stopPropagation();

        const formData = {};
        for (let i = 0; i < e.target.length - 1; i++)
        {
            if (e.target[i].type === "number")
            {
                formData[e.target[i].name] = Number(e.target[i].value);
            }
            else
            {
                formData[e.target[i].name] = e.target[i].value;
            }
        }

        const reqBody = {
            type: aiToolMetadata.endpoint,
            message: JSON.stringify(formData),
        };
        setRequestBody(reqBody);
        sendLLMRequest(reqBody);
    }

    function sendLLMRequest(reqBody)
    {
        setIsLoading(true);
        fetch("https://api.spatialgrowclassroom.com/coursecreator/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
        })
        .then(response => response.json())
        .then(json => {
            setDataResponse(json);
            setIsLoading(false);
        })
        .catch(error => {
            console.error(error);
            setIsLoading(false);
        })
    }

    function handleRegenerate()
    {
        // setDataResponse(null);
        sendLLMRequest(requestBody);
    }

    return (
        <div className="">
            { aiToolMetadata && !isLoading && (dataResponse === null ?
                <AIToolForm
                    title={aiToolMetadata.title}
                    description={aiToolMetadata.description}
                    inputsMetadata={aiToolMetadata.inputs}
                    handleOnSubmit={submitAIToolForm}
                />
                :
                <AIToolResult
                    results={dataResponse}
                    title={aiToolMetadata.title}
                    toolType={aiToolMetadata.endpoint}
                    handleReset={() => setDataResponse(null)}
                    handleRegenerate={handleRegenerate}
                />)  
            }

            { isLoading &&
                <div className="ai-tool-loading">
                    <h3>{"Generating " + aiToolMetadata.title}... Please wait.</h3>
                    <Oval 
                        height={200}
                        width={200}
                        color="#5949a8"
                        ariaLabel="oval-loading"
                        secondaryColor="#503db3"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </div>
            }
            
        </div>
    )
}