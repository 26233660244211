import { useState, useEffect } from "react";
import { firebaseRegisterForCourse } from '../../assets/js/firebase-config';
import Modal from "./Modal";
import { Oval } from "react-loader-spinner";
import ErrorMessage from "../messages/ErrorMessage";
import SuccessMessage from "../messages/SuccessMessage";
import { refreshUserData, getCurrentTimestamp, updateActivityHistory } from "../../assets/js/firebase-utils";

function RegisterClassForm({courseDetails, setIsValidatingRegister, setValidationStatus})
{
    async function handleRegisterClass(e)
    {
        e.preventDefault();
        const form = e.target;
        const formData = Object.fromEntries(new FormData(form).entries());
          
        // setIsValidatingCode(true);
        const registerData = {
            courseID: courseDetails.id,
            classLabel: formData.classLabel,
            gradeLevel: formData.gradeLevel,
            courseName: courseDetails.course_name,
        };
        console.log(registerData);
        setIsValidatingRegister(true);

        const result = await firebaseRegisterForCourse(registerData
            ).then(result => {
                const data = result.data;
                setIsValidatingRegister(false);
                setValidationStatus(data);
                return data;
            }).catch(e => {
                console.error(e);
                setIsValidatingRegister(false);
                return {status: 400};
        });
        if (result.status === 200)
        {
            const createdClass = result.created_class;
            const activity = {
                name: `Created class for ${courseDetails.course_name}`,
                type: "Created Class",
                date_completed: getCurrentTimestamp(),
                source: `/courses/${createdClass.class_id}/${createdClass.course_id}`,
            };
            await updateActivityHistory(activity);
            await refreshUserData();
        }
        
    }

    return (
        <form method="post" onSubmit={handleRegisterClass} className="register-class-form">
            <div className="register-class-inputs">
                <span className="c-input-container">
                    <input className="c-input-text-field" name="classLabel" type="text" />
                    <label htmlFor="classLabel" className="c-label">Class Label</label>
                </span>
                <span className="c-input-container">
                    <select name="gradeLevel" type="text">
                        <option value="Elementary School">Elementary School</option>
                        <option value="Middle School">Middle School</option>
                        <option value="High School">High School</option>
                    </select>                
                    <label htmlFor="gradeLevel" className="c-label">Grade Level</label> 
                </span>
            </div>
            <span className="register-class-form-button">
                <button className="c-button" type="submit">Register New Class</button>
            </span>
        </form>
    );
}


export default function RegisterClassModal({courseDetails})
{
    const [openModal, setOpenModal] = useState(false);
    const [isValidatingRegister, setIsValidatingRegister] = useState(false);
    const [validationStatus, setValidationStatus] = useState("");

    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFooter, setModalFooter] = useState("");

    function closeModal()
    {
        setOpenModal(false);
        setValidationStatus(false);
    }

    useEffect(()=> {
        if (isValidatingRegister)
        {
            setModalTitle("Creating a new class...");
            setModalBody( <Oval 
                height={150}
                width={150}
                color="#5949a8"
                ariaLabel="oval-loading"
                secondaryColor="#503db3"
                strokeWidth={3}
                strokeWidthSecondary={3}
            />);
            setModalFooter("");
        }
        else {
            setModalTitle("Register a New Class");
            setModalFooter(
                <div>
                    This will create a new class for{" "}
                    <span className="c-register-class-footer-course-name">
                        {courseDetails.course_name}
                    </span>
                    .{" "}
                    If this is the incorrect course, please exit out and select a different course.
                </div>
            );

            if (validationStatus.status === 400)
            {
                setModalBody(
                    <div>
                        <RegisterClassForm 
                            courseDetails={courseDetails}
                            setIsValidatingRegister={setIsValidatingRegister}
                            setValidationStatus={setValidationStatus}
                            />
                        <ErrorMessage msg={validationStatus.error_msg} /> 
                    </div>
                );
            }
            else if (validationStatus.status === 200)
            {
                setModalBody(
                    <div>
                        <RegisterClassForm 
                            courseDetails={courseDetails}
                            setIsValidatingRegister={setIsValidatingRegister}
                            setValidationStatus={setValidationStatus}
                            />
                        <SuccessMessage msg={`Class successfully registered! The course code is ${validationStatus.created_class.course_code}`} /> 
                    </div>
                );
            }
            else {
                setModalBody(
                    <RegisterClassForm 
                        courseDetails={courseDetails}
                        setIsValidatingRegister={setIsValidatingRegister}
                        setValidationStatus={setValidationStatus}
                    />
                );
            }
        }
    }, [openModal, isValidatingRegister, validationStatus]);

    return (
        <>
            <button className="c-button" onClick={() => setOpenModal(true)}>Register New Class</button>
            { openModal &&
                <Modal 
                    closeModal={closeModal}
                    title={modalTitle}
                    body={modalBody}
                    footer={modalFooter}
                    width={600}
                    height={600}
                />
            }
        </>
    );
}
