import LessonArticle from "../../class/LessonArticle";

export default function AILessonArticleResults({results})
{
    function formatLessonDetails()
    {
        const lessonDetails = {
            "content": {"sections": results.sections},
            "name": results.lesson,
        };

        return lessonDetails;

    }

    return (
        <div className="c-page-container">
            <LessonArticle lessonDetails={formatLessonDetails()} />
        </div>
    )
}