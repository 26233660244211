import { useRef } from "react";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Modal({closeModal, title, body, footer, width=500, height=500})
{
    const modalRef = useRef(null);

    function handleBackgroundClickClose(e)
    {
        if (modalRef.current && modalRef.current === e.target)
        {
            e.stopPropagation();
            closeModal();
        }
    }

    return (
        <div className="c-modal-background" onClick={handleBackgroundClickClose} ref={modalRef}>
            <div className="c-modal-container" style={{width: width, height: height}}>
                <div className="c-modal-close-btn-container">
                    <button className="c-modal-close-btn" onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                </div>
                <div className="c-modal-title">
                    <h1>{title}</h1>
                </div>
                <div style={{flex: footer ? "60%" : "100%"}}className="c-modal-body">
                    {body}
                </div>
                { footer &&
                    <div className="c-modal-footer">
                        {footer}
                    </div>
                }
                
            </div>
        </div>
    )
}
