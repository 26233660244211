import { faBookOpen, faFilePen, faCode, faBook } from '@fortawesome/free-solid-svg-icons';

const AIToolsMetadata = [
    {
        title: "Multiple Choice Quiz",
        description: "Create a multiple-choice quiz tailored to any course or subject matter.",
        icon: faFilePen,
        endpoint: "Quiz",
        link: "multiple-choice-quiz",
        inputs: [
            {
                type: "text",
                name: "Course",
                label: "Course, Subject, or Topic",
                placeholder: "Introduction to Computer Science with Python",
            },
            {
                type: "text",
                name: "GradeLevel",
                label: "Grade Level",
                placeholder: "10th Grade",
            },
            {
                type: "number",
                name: "NumberOfQuestions",
                label: "Number of Questions (1 - 20)",
                placeholder: "10",
                limits: [1, 20], 
            },
            {
                type: "textarea",
                name: "Description",
                label: "Description of Content Covered in the Quiz",
                placeholder: "Functions in Python, the syntax to define the function, ... ",
            },
        ]
    },
    {
        title: "Lesson Plan",
        description: "Create a lesson plan tailored to any course or subject matter.",
        icon: faBookOpen,
        endpoint: "LessonPlan",
        link: "lesson-plan",
        inputs: [
            {
                type: "text",
                name: "Topic",
                label: "Course, Subject, or Topic",
                placeholder: "Sustainable Agriculture Production",
            },
            {
                type: "text",
                name: "GradeLevel",
                label: "Grade Level",
                placeholder: "10th Grade",
            },
            {
                type: "textarea",
                name: "Description",
                label: "Description of Content Covered in the Lesson Plan",
                placeholder: "Create a lesson plan that teaches students the importance of sustainable agriculture production. It should cover why it's crucial for both our planet and its inhabitants.",
            },
        ]
    },
    {
        title: "Python Activity",
        description: "Create a custom Python activity.",
        icon: faCode,
        endpoint: "PythonActivity",
        link: "python-activity",
        inputs: [
            {
                type: "text",
                name: "Topic",
                label: "Course, Subject, or Topic",
                placeholder: "Introduction to Variables in Python",
            },
            {
                type: "text",
                name: "GradeLevel",
                label: "Grade Level",
                placeholder: "10th Grade",
            },
            {
                type: "textarea",
                name: "Description",
                label: "Description of Content Covered in the Python Activity",
                placeholder: "Create a Python activity for teaching students about variables and how to perform operations on variables.",
            },
        ]
    },
    {
        title: "Lesson Article",
        description: "Create an article for a lesson.",
        icon: faBook,
        endpoint: "LessonArticle",
        link: "lesson-article",
        inputs: [
            {
                type: "text",
                name: "Topic",
                label: "Course, Subject, or Topic",
                placeholder: "Python if Statement",
            },
            {
                type: "text",
                name: "GradeLevel",
                label: "Grade Level",
                placeholder: "10th Grade",
            },
            {
                type: "textarea",
                name: "Description",
                label: "Description of Content Covered in the Lesson Article",
                placeholder: "Create a lesson article explaining how if statements work in Python.",
            },
        ]
    },    
];


export { AIToolsMetadata }