import { useEffect, useState } from 'react';
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { Link, useParams } from 'react-router-dom';
import { useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";


function CourseDetailsContent({courseDetails})
{
    useEffect(() => {


        // const unityIframe = document.getElementById("unity-iframe");
		// Send the user & course/lesson information over to the Unity iframe
        // window.addEventListener(
        //     'message',
        //     (e) => {
        //         const { data } = e;
        //         if (data.pageLoaded) {
        //             // unityIframe.contentWindow.postMessage({
        //             //     "courseCode": sessionStorage.getItem("courseCode"),
        //             //     "lessonCode": sessionStorage.getItem("lessonCode"),
        //             //     "email": sessionStorage.getItem("email")
        //             // }, "*"); // TODO: Specify the targetOrigin
        //             unityIframe.contentWindow.postMessage({
        //                 "courseCode": "16",
        //                 "lessonCode": "fooLessCode",
        //                 "email": "foo@email.com"
        //             }, "*"); // TODO: Specify the targetOrigin
        //         }
        //     },
        //     false,
        //     );
    });
    return (
        <div className="c-page-container course-details-container">
            <div className="course-details-header">

                <div className="blog-image">
                    <img src={courseDetails.course_image} />
                </div>
                <h3 className="course-details-title">{courseDetails.course_name}</h3>
                <p className="course-details-description">{courseDetails.course_description}</p>
            </div>
            <div>
                {courseDetails.course_overview && courseDetails.course_overview.map((unit, unitIdx) => {
                    return (
                        <div className="unit-container" key={unitIdx}>
                            <h3 className="unit-header"><Link to={`/courses/${courseDetails.classID}/${courseDetails.id}/${unit.unit_id}`}>Unit {unitIdx + 1}: {unit.unit_name}</Link></h3>
                            <ul>
                                {unit.lessons.map((lesson, lessonIdx) => {
                                    return (
                                        <li className="lesson-name-link" key={lessonIdx}>
                                            {!courseDetails.activeLessons || courseDetails.activeLessons[unitIdx].lessons[lessonIdx].enabled ?
                                                <Link to={`/courses/${courseDetails.classID}/${courseDetails.id}/${unit.unit_id}/${lesson.lesson_id}`}>{lessonIdx + 1}. {lesson.lesson_name}</Link>
                                            :
                                             <span className="disabled-link">{lessonIdx + 1}. {lesson.lesson_name}</span>   
                                            }
                                           
                                        </li>
                                        
                                    );
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export function CourseDetails()
{
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);
    const { classID, courseID } =  useParams();
    const [courseDetails, setCourseDetails] = useState("");
    
    useEffect(() =>
    {
        const fetchData = async() => {
            try {
                if (!courseDetails)
                {
                    const storedUser = JSON.parse(localStorage.getItem("user"));

                    const courseRef = doc(firestoreDb, "Courses", courseID);
                    const docSnap = await getDoc(courseRef);
                    const courseData = docSnap.data();
                    courseData.id = docSnap.id;
                    courseData.classID = classID;

                    // Get the class settings
                    if (storedUser.user_type === "student")
                    {
                        const classSettingsRef = doc(firestoreDb, `ClassSettings/${classID}`);
                        const classSettingsDoc = await getDoc(classSettingsRef);
                        const classSettingsData = classSettingsDoc.data();
                        courseData.activeLessons = classSettingsData.active_lessons;
                    }
                     

                    setCourseDetails(courseData);
                    setPageHeader(courseData.course_name);
                }
                else {
                    setPageHeader(courseDetails.course_name);
                }


            } catch (err)
            {
                console.error(err);
            }

        };
        fetchData();
    }, []);
    
    return (
        // <div className="page-header">
        //      <div className="container">
        //         <div className="row">
        //             <div className="col">
        //                 <h3 className="page-title">Course Details</h3>
        //                 <ul className="breadcrumb">
        //                     <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
        //                     <li className="breadcrumb-item"><a href="/courses">Courses</a></li>
        //                     <li className="breadcrumb-item active">Course Details</li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>


        // </div>
        <>
            { courseDetails === {}  ? <div></div> :  <CourseDetailsContent courseDetails={courseDetails} /> }    
        </>
        );
}

// export function courseDetailsLoader({ params }) {
//     const { courseID } = params;

//     if (!validCourseIds.has(courseID))
//     {
//         throw Error("Invalid CourseID: " + courseID);
//     }
//     return courseID;
// }