
export default function AIToolForm({title, description, inputsMetadata, handleOnSubmit})
{
    function constructInput(inputMetadata, key)
    {
        console.log(inputMetadata);
        if (inputMetadata.type === "text")
        {
            return (
                <div className="c-input-container ai-tool-form-input" key={key}>
                    <input type="text" name={inputMetadata.name} className="c-input-text-field" placeholder={inputMetadata.placeholder} required="required"/>
                    <label className="c-label" htmlFor={inputMetadata.name}>{inputMetadata.label}</label>
                </div>
            );
        }
        else if (inputMetadata.type === "textarea") {
            return (
                <div className="c-input-container ai-tool-form-input" key={key}>
                    <textarea name={inputMetadata.name} className="c-input-text-field" rows="5" placeholder={inputMetadata.placeholder} required="required"/>
                    <label className="c-label" htmlFor={inputMetadata.name}>{inputMetadata.label}</label>
                </div>
            );
        }
        else if (inputMetadata.type === "number") {
            return (
                <div className="c-input-container ai-tool-form-input" key={key}>
                    <input type="number" name={inputMetadata.name} className="c-input-text-field" placeholder={inputMetadata.placeholder} min={inputMetadata.limits[0]} max={inputMetadata.limits[1]}/>
                    <label className="c-label" htmlFor={inputMetadata.name}>{inputMetadata.label}</label>
                </div>
            );
        }
    }

    return (
        <div className="ai-tool-form-container c-page-container">
            <div className="ai-tool-form-header">
                <h3 className="ai-tool-header">{title}</h3>
                <p>{description}</p>
            </div>
            <form className="ai-tool-form" onSubmit={handleOnSubmit}>
                { 
                    inputsMetadata.map((val, key) => {
                        return constructInput(val, key);
                    })
                }
                <span>
                    <button className="c-button" style={{width: "500px"}}>Generate</button>
                </span>
            </form>
        </div>
    )
    
}