import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function AIToolSelectBox({title, description, icon})
{
    return (
        <div className="ai-tools-select-box c-panel-container">
            <FontAwesomeIcon className="ai-tools-select-box-icon" icon={icon} size="2x"/>
            <div className="ai-tools-select-box-info-container">
                <div className="ai-tools-select-box-title">
                    <p>{title}</p>
                </div>
                <div className="ai-tools-select-box-description">
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}
