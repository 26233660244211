import { useEffect, useState } from "react";
import Modal from "./Modal";
import { firebaseRegisterUser, firebaseValidateSubscriptionCode } from '../../assets/js/firebase-config';
import { auth, firestoreDb } from '../../assets/js/firebase-config';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Oval } from "react-loader-spinner";
import ErrorMessage from "../messages/ErrorMessage";
import SuccessMessage from "../messages/SuccessMessage";
import { setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getCurrentTimestamp, updateActivityHistory  } from "../../assets/js/firebase-utils";



function SignUpForm({setValidationStatus, handleLogin, savedFormData, setSavedFormData, accountType, setAccountType, subCodeValidationResults, setSubCodeValidationResults})
{
    const navigation = useNavigate();
    const [ disableSubmitButton, setDisableSubmitButton ] = useState(false);
     

    function getFormDataValue(value)
    {
        if(savedFormData && savedFormData[value])
        {
            return savedFormData[value];
        }
        else {
            return "";
        }
    }

    function updateFormData(e)
    {
        const updatedFormData = {...savedFormData};
        updatedFormData[e.target.name] = e.target.value;
        setSavedFormData(updatedFormData);
    }

    async function validateSubscriptionCode(e) {
        e.preventDefault();
        e.stopPropagation();
        const subscriptionCodeForm = new FormData(e.target);
        const requestData = {
            subscriptionCode: subscriptionCodeForm.get("subscriptionCode"),   
        };
        const result = await firebaseValidateSubscriptionCode(requestData).then(
            result => {
                const data = result.data;
                console.log(data);
                if (!data.status || data.status !== 200)
                {
                    setValidationStatus(
                        {
                            status: 400,
                            error_msg: data.error_msg,
                        }
                    );
                    
                }
                else {
                    setSubCodeValidationResults(data);
                    setValidationStatus("");
                }
            }
        );
    }

    async function handleSignUp(e) {
        e.preventDefault();
        e.stopPropagation();
        setDisableSubmitButton(true);
        
        // Get the form data
        const form = e.target;
        const formData = Object.fromEntries(new FormData(form).entries());
        let shouldCreate = true;

        for (let key in formData)
        {
            if (key !==  "preferredName" && !formData[key])
            {
                setValidationStatus({
                    status: 400,
                    error_msg: "Please fill in all the required properties", 
                })
                setDisableSubmitButton(false);
                shouldCreate = false;
                return;
            }
        }

        if (formData["password"] !== formData["confirmPassword"]) {
            setValidationStatus({
                status: 400,
                error_msg: "Passwords must match.",
            })
            shouldCreate = false;
        }
        
        if (shouldCreate)
        {
            await createUserWithEmailAndPassword(auth, formData.email, formData.password).then(async (userCredentials) => {
                const user = userCredentials.user;
    
                const signUpActivity = {
                    name: "Created Account",
                    type: "Account Creation",
                    date_completed: getCurrentTimestamp(),
                };
                const registerData = {
                    formData: formData,
                    accountType: accountType,
                    subCodeValidationResults: subCodeValidationResults,
                    
                };
    
                const registerResults = await firebaseRegisterUser(registerData);

                if (registerResults.data.status !== 200)
                {
                    setDisableSubmitButton(false);
                    setValidationStatus(
                        {
                            status: 400,
                            error_msg: "Something went wrong when trying to create your account. Please contact us.",
                        }
                    );
                    return;
                }

                await handleLogin(user);
                await updateActivityHistory(signUpActivity);
                navigation("/dashboard");
            }).catch(error => {
                console.log(error);
                setDisableSubmitButton(false);
                console.log(error.message);
                if (error.message.includes("email-already-in-use"))
                {
                    setValidationStatus(
                        {
                            status: 400,
                            error_msg: "An account already exists with that email.",
                        }
                    );
        
                }
                else if(error.message.includes("invalid-email"))
                {
                    setValidationStatus(
                        {
                            status: 400,
                            error_msg: "Please enter in a valid email.",
                        }
                    );
                }
                else {
                    setValidationStatus(
                        {
                            status: 400,
                            error_msg: "Something went wrong... Please try again.",
                        }
                    );
                }
            })
        }
    }

    return (
        <>
        { accountType === "" && 
            <div className="sign-up-account-type">
                <button className="c-button" onClick={() => setAccountType("student")}>Student</button>
                <button className="c-button" onClick={() => setAccountType("teacher")}>Teacher</button>
            </div>
        }
        { accountType === "teacher" && subCodeValidationResults === null  && 
            <form className="sign-up-teacher-code" onSubmit={validateSubscriptionCode}>
                <span className="course-code-input-container">
                    <div className="c-input-container">
                        <input type="text" name="subscriptionCode" className="c-input-text-field" />
                        <label className="c-label" htmlFor="subscriptionCode">Subscription Code</label>
                    </div>
                    <button className="c-button" style={{width: "100px"}} >Validate</button>
                </span>
            </form>
        }
                    
        { (accountType === "teacher" && subCodeValidationResults !== null || accountType == "student") &&
        <div className="sign-up-form-container">

            <form className="sign-up-form" onSubmit={handleSignUp}>
                <div className="c-input-container">
                    <input type="text" name="firstName" className="c-input-text-field" value={getFormDataValue("firstName")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="firstName">First Name</label>
                </div>

                <div className="c-input-container">
                    <input type="text" name="lastName" className="c-input-text-field" value={getFormDataValue("lastName")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="lastName">Last Name</label>
                </div>
                <div className="c-input-container">
                    <input type="text" name="preferredName" className="c-input-text-field" value={getFormDataValue("preferredName")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="preferredName">Preferred Name (optional)</label>
                </div>
                <div className="c-input-container">
                    <input type="text" name="email" className="c-input-text-field" value={getFormDataValue("email")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="email">Email</label>
                </div>
                <div className="c-input-container">            
                    <input type="password" name="password" className="c-input-text-field" value={getFormDataValue("password")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="password">Password</label>
                </div>
                <div className="c-input-container">            
                    <input type="password" name="confirmPassword" className="c-input-text-field" value={getFormDataValue("confirmPassword")} onChange={updateFormData} />
                    <label className="c-label" htmlFor="confirmPassword">Confirm Password</label>
                </div>

                <span>
                    <button className="c-button" style={{width: "100px"}} disabled={disableSubmitButton} >Sign up</button>
                </span>

            </form>
        </div>
        }
  
        
        </>
    );
}

export default function SignUpModal({handleLogin, setShowSignUpModal})
{
    const [isValidatingSignup, setIsValidatingSignup] = useState(false);
    const [validationStatus, setValidationStatus] = useState("");
    const [ savedFormData, setSavedFormData ] = useState({});
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFooter, setModalFooter] = useState("");
    const [accountType, setAccountType] = useState("");
    const [subCodeValidationResults, setSubCodeValidationResults] = useState(null);

    function closeModal() {
        setShowSignUpModal(false);
        setValidationStatus("");
        setAccountType("");
        setSubCodeValidationResults(null);
    }

    useEffect(() => {
        if (isValidatingSignup)
        {

        }
        else {
            if (accountType === "")
            {
                setModalTitle("What type of account are you creating?");
                setModalFooter("");
            }
            else if (accountType === "teacher" && subCodeValidationResults === null)
            {
                setModalTitle("Please enter a valid subscription code");
                setModalFooter("You must receive this code from us directly. If you haven't received a code or you are unaware of what your code is, please contact us.")
            }
            else {
                console.log("Teacher Code: " + subCodeValidationResults);
                setModalTitle("Sign up");
                setModalFooter("");
            }
            
            if (validationStatus.status === 400)
            {
                setModalBody(
                    <div>
                        <SignUpForm
                            setValidationStatus={setValidationStatus}
                            handleLogin={handleLogin}
                            savedFormData={savedFormData}
                            setSavedFormData={setSavedFormData}
                            accountType={accountType}
                            setAccountType={setAccountType}
                            subCodeValidationResults={subCodeValidationResults}
                            setSubCodeValidationResults={setSubCodeValidationResults}
                        />
                        <ErrorMessage msg={validationStatus.error_msg} />
                    </div>
                )
            }
            else {
                setModalBody(<SignUpForm 
                    setValidationStatus={setValidationStatus}
                    handleLogin={handleLogin}
                    savedFormData={savedFormData}
                    setSavedFormData={setSavedFormData}
                    accountType={accountType}
                    setAccountType={setAccountType}
                    subCodeValidationResults={subCodeValidationResults}
                    setSubCodeValidationResults={setSubCodeValidationResults}/>);
            }
        }

    }, [isValidatingSignup, validationStatus, savedFormData, accountType, subCodeValidationResults]);

    function getModalHeight()
    {
        if (accountType === "")
        {
            return 400;
        }
        else if (accountType === "teacher" && subCodeValidationResults === null)
        {
            return 500;
        }
        else {
            return 800;
        }
    }

    return (
        <>
            <Modal 
                closeModal={closeModal}
                title={modalTitle}
                body={modalBody}
                footer={modalFooter}
                width={500}
                height={getModalHeight()}
            /> 
            
        </>
    );
}