import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { PageHeaderContext } from "../components/PageHeaderContext";
import { useEffect } from "react";
import ActivityHistory from "../components/ActivityHistory";
import TeacherDashboard from "../components/dashboard/TeacherDashboard";

export default function Dashboard({handleLogout}) {
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);

    
    useEffect(()=> {
        setPageHeader("Dashboard");
    });

    function createWelcomeMessage()
    {
        let welcomeMsg = "Welcome";
        let user;
        try {
            user = JSON.parse(localStorage.getItem("user"));
        }
        catch(error) 
        {
            handleLogout();
            return "";
        }
        
        if (user === null || user === undefined)
        {
            handleLogout();
            return "";
        }

        console.log(user);
        if (user.hasOwnProperty("userData"))
        {
            welcomeMsg += ` ${user.userData.first_name}`;
        }
        welcomeMsg += "!";
        return welcomeMsg

    }

    return (
        <div className="dashboard-container">

            <div className="dashboard-header">
                <h2>{createWelcomeMessage()}</h2>
                {/* <p>Go to <Link to="/courses">courses.</Link></p> */}
            </div>
            { JSON.parse(localStorage.getItem("user")).user_type === "teacher" && 
                <>
                    <TeacherDashboard />
                    <div className="c-vertical-container-spacing"></div>
                </>
            }
            
            <ActivityHistory title="Recent Actions History" activities={JSON.parse(localStorage.getItem("user")).activity_history}/>
        </div>
    )
}