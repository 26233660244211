import { closest } from "fastest-levenshtein";



const questionPrefix = ["A.", "B.", "C.", "D."];

function AIQuizQuestion({idx, question, options, answer})
{
    // Force it to choose an actual valid answer
    function getClosest()
    {
        // Get the closest answer
        const closestAnswer = closest(answer, options);
        
        // Get the index of the answer
        const closestAnswerIdx = options.findIndex((option) => option === closestAnswer);
        
        return questionPrefix[closestAnswerIdx]
    }   

    return (
        <div className="ai-quiz-question-container">
            <h4 className="ai-quiz-question">{(idx + 1) + ". " +  question}</h4>
            <div className="ai-quiz-options">
                {
                    options.map((option, key) =>
                        <p className="ai-quiz-option" key={"option-" + key}>{questionPrefix[key] + " " + option}</p>
                    )
                }
            </div>
            <p className="ai-quiz-answer">{"Answer: " + getClosest() + " " + answer}</p>
        </div>
    )
}

export default function AIQuizResults({results})
{
    return (
        <>
        { results &&
            <div className="c-page-container ai-result-container">
            {
                results.map((question, key) => {
                    return <AIQuizQuestion
                        idx={key}
                        question={question.question}
                        options={question.options}
                        answer={question.answer}
                        key={key}
                    />
                })
            }
        </div>
        }
        </>
        
    );
}
