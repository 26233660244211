import Modal from "./Modal";
import { useState, useEffect } from "react";
import ErrorMessage from "../messages/ErrorMessage";

export default function ErrorModal({setOpenModal, errorMsgTitle, errorMsgBody, width=500, height=500})
{
    return (
        <>
            <Modal 
                closeModal={setOpenModal}
                title={<ErrorMessage msg={errorMsgTitle} />}
                body={errorMsgBody}
                width={width}
                height={height}
            />
        </>
    );
}