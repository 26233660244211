const SEC_PER_DAY = 86400;
const MS_PER_SEC = 1000;

function getFormattedDate(date, includeYear=false) {
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    if (includeYear) {
        let year = date.getFullYear().toString();
        return month + '/' + day + '/' + year;
    } else {
        return month + '/' + day;
    }
}


function generateDateArray(numDates) {
    const secSinceEpoch = Date.now();

    // Generate the list of dates
    const dates = [];
    for (let i = numDates - 1; i >= 0; i--) {
        let dateSeconds = secSinceEpoch - SEC_PER_DAY * i;
        let tempDate = new Date(dateSeconds * MS_PER_SEC);
        dates.push(getFormattedDate(tempDate));  
    }

    return dates;
}

function isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
}

function countDates(dates) {
    const dateCount = {};
    for (let i = 0; i < dates.length; i++) {
        const curDate = new Date(dates[i].seconds * MS_PER_SEC);
        const formattedDate = getFormattedDate(curDate, true); 
        if (dateCount.hasOwnProperty(formattedDate)) {
            dateCount[formattedDate] += 1;
        } else {
            dateCount[formattedDate] = 1;
        }
    }
    return dateCount;    
}

function getRecentDateCount(dateCount, numDays=30) {
    const recentDates = [];
    const counts = [];
    for (let i = numDays - 1; i >= 0; i--)
    {  
        // Get the date that happened i days ago
        let curDate = new Date();
        curDate.setDate(curDate.getDate() - i);
        const formattedDate = getFormattedDate(curDate, true);
        
        // Add the label
        const yearlessDate = getFormattedDate(curDate);
        recentDates.push(yearlessDate);

        // Add the count
        if (dateCount.hasOwnProperty(formattedDate)) {
            counts.push(dateCount[formattedDate]);
        } else {
            counts.push(0);
        }
    }

    return [recentDates, counts];
}

export { generateDateArray, countDates, getRecentDateCount }