import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function createColumns(data)
{
    const groupedColumns = [
        {
            Header: "Name",
            Footer: "Name",
            columns: [
                {
                    Header: "First Name",
                    Footer: "First Name",
                    accessor: "first_name",
                },
                {
                    Header: "Last Name",
                    Footer: "Last Name",
                    accessor: "last_name",
                },
            ]
        }
    ];
    const groupedUnits = {};
    for (let i = 0; i < data.length; i++)
    {
        const curItem = data[i];
        const curKey = `Unit ${curItem.unit.unit_idx + 1}`;
        const lessonIdx = curItem.lesson.lesson_idx;
        const unitIdx = curItem.unit.unit_idx;

        if (curKey in groupedUnits)
        {
            groupedUnits[curKey].columns.push({
                Header: `Lesson ${lessonIdx + 1} Quiz`,
                Footer: `Lesson ${lessonIdx + 1} Quiz`,
                accessor: `unit_${unitIdx}_lesson_${lessonIdx}_quiz`,
            });
        } else { 
            groupedUnits[curKey] = {
            Header: curKey,
            Footer: curKey,
            columns: [
                {
                Header: `Lesson ${lessonIdx + 1} Quiz`,
                Footer: `Lesson ${lessonIdx + 1} Quiz`,
                accessor: `unit_${unitIdx}_lesson_${lessonIdx}_quiz`
                }
            ]};
        }
    }

    // Add created the grouped unit columns
    for (let k in groupedUnits)
    {
        groupedColumns.push(groupedUnits[k]);
    }

    return groupedColumns;
}

function unwrapStudentData(assignData, students)
{
    // Set up the rows for each student
    const studentData = {};
    for (let i = 0; i < students.length; i++)
    {
        studentData[students[i].student_id] = {
            id: students[i].student_id,
            first_name: students[i].first_name,
            last_name: students[i].last_name,
        };
    }


    // Put in the grades for each assignment into the rows
    for (let i = 0; i < assignData.length; i++)
    {
        const grades = assignData[i].student_grades;
        const unitIdx = assignData[i].unit.unit_idx;
        const lessonIdx = assignData[i].lesson.lesson_idx;
        const assignName = `unit_${unitIdx}_lesson_${lessonIdx}_${assignData[i].type}`;

        // Iterate over all the grades for this assignment
        for (let j = 0; j < grades.length; j++)
        {
            const curGrade = grades[j];
            // Verify the student id is current attending the class
            if (curGrade.student_id in studentData)
            {
                studentData[curGrade.student_id][assignName] = Math.round(curGrade.grade);
            }
        }
    }

    // Unwrap the object
    const studentDataArr = [];
    for (let k in studentData)
    {
        studentDataArr.push(studentData[k]);
    }

    return studentDataArr;
}

const exportToCSV = (apiData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Remove the first column data ('id' is the key for the first column)
    const modifiedData = apiData.map(item => {
        const { id, ...rest } = item;  // Adjust 'id' to the whatever key you want to omit
        return rest;
    });

    const ws = XLSX.utils.json_to_sheet(modifiedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

export { createColumns, unwrapStudentData, exportToCSV };