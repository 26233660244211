
import { faFilePen } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect } from 'react';
import { PageHeaderContext } from '../../components/PageHeaderContext';
import AIToolSelectBox from '../../components/ai/AIToolSelectBox';
import { AIToolsMetadata } from '../../components/ai/AIToolsData';
import { Link } from 'react-router-dom';

export default function AIToolsDashboard()
{
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);
    useEffect(() => {
        setPageHeader("AI Tools");
    }, []);

    return (
        <div>
            <h2 className="ai-tools-dashboard-header">AI Tools to Assist in Generating Your Course Material</h2>
            <div className="ai-tools-dashboard-container">
            {
                AIToolsMetadata.map((tool, key) => {
                    return (
                            <Link to={tool.link} key={key}>
                                <AIToolSelectBox 
                                    title={tool.title} 
                                    description={tool.description}
                                    icon={tool.icon}
                                /> 
                            </Link>
                    );
                })
            }
            </div>
        </div>
    );
}