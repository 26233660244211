import GradebookTable from "../../components/table/GradebookTable";
import { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { collection, getDocs, getDoc, doc} from "firebase/firestore";
import { firestoreDb } from '../../assets/js/firebase-config';
import ToggleLessons from "../../components/class/ToggleLessons";
import { createColumns, unwrapStudentData,exportToCSV } from "./utils";

export default function ClassGradebook()
{
    const { classID } =  useParams();
    const { setPageHeader} = useContext(PageHeaderContext);
    const [activeClass, setActiveClass ] = useState();
    const [groupedColumns, setGroupedColumns] = useState();
    const [studentData, setStudentData] = useState();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const colRef = collection(firestoreDb, `Class/${classID}/Assignments`)
                const assignSnap = await getDocs(colRef);
                const assignData = assignSnap.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                const classRef = doc(firestoreDb, `Class/${classID}`);
                const classDoc = await getDoc(classRef);
                const classData = classDoc.data();
                const students = classData.students; 

                // Sort them by first unit and then by lesson
                assignData.sort((a, b) => {;
            
                    // Compare by unit
                    if (a.unit.unit_idx !== b.unit.unit_idx) {
                    return a.unit.unit_idx - b.unit.unit_idx;
                    }
            
                    // If unit_idx are equal, compare by lesson_idx
                    return a.lesson.lesson_idx - b.lesson.lesson_idx;
                });

                // Build the columns
                setGroupedColumns(createColumns(assignData));
                
                // Create the student data
                setStudentData(unwrapStudentData(assignData, students));
            }
            catch (error)
            {
                console.error(error);
            }
        };

        // Get the metadata about the class
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const curActiveClass = user.userData.active_classes.find(c => c.class_id === classID);
            setActiveClass(curActiveClass);
            setPageHeader(`${curActiveClass.class_label} Gradebook`);
        }
        catch (error) {
            console.error(error);
        }

        fetchData();
    }, []);
    return (
        <>
            <div className="c-page-container">
                {studentData &&
                    <>
                        <GradebookTable
                            groupedColumns={groupedColumns}
                            rows={studentData}
                            tableHeader={`Grades for ${activeClass.course_name}`}
                        />
                    <div className="export-button-container">
                        <button className="c-button" onClick={() => exportToCSV(studentData, "Gradebook")}>Export Grades</button>
                    </div>
                    </>
                }
            </div>
            { classID &&
                <ToggleLessons classID={classID}/>
            }
        </>
    );
}
