import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faSchool, faClock } from '@fortawesome/free-solid-svg-icons'
import SingleMetricBox from "../../components/analytics/SingleMetricBox";
import VerticalDivider from "../../components/general/VerticalDivider";
import DailyActiveUsers from "../../components/analytics/DailyActiveUsers";
// [#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"]




export default function AnalyticsDashboard()
{

    return (
        <div>
            <div className="analytics-dashboard-header">
                <SingleMetricBox icon={faUsers} metric={1000} title={"Total Students"} color={"#50e991"}/>
                <VerticalDivider height={105} width={1.5}/>
                <SingleMetricBox icon={faSchool} metric={35} title={"Number of Classes"} color={"#0bb4ff"}/>  
                <VerticalDivider height={105} width={1.5} />
                <SingleMetricBox icon={faClock} metric={100000} title={"Time Spent Learning"} color={"#e60049"}/>
            </div>
            <div style={{width: 950}}>
                <DailyActiveUsers />
            </div>
        </div>
    );
}