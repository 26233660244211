import { 
    Navigate,
    Outlet,
  } from "react-router-dom";

export default function ProtectedRoute({
    isAllowed,
    redirectPath = '/',
    children,
  }) {
    if (isAllowed) {
        return children ? children : <Outlet />;
    }
    return <Navigate to={redirectPath} replace />;
  
    
}