
export default function VerticalDivider({height=100, width=1})
{
    return (
        <div style={
            {
                height: height,
                borderRight: `${width}px solid rgba(110, 110, 110, 0.247)`,
                margin: "auto 0"
            }
            }>

        </div>
    );
}