import { Link, useNavigate, useParams } from "react-router-dom";
import EmblaCarousel from "../../components/Slideshow";
import { useEffect, useState } from "react";
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { LearningObjectives, LessonDetails } from "./LessonParts";
import { useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faGamepad, faCode} from '@fortawesome/free-solid-svg-icons'
import LessonWithSlides from "../../components/class/LessonWithSlides";
import LessonArticle from "../../components/class/LessonArticle";


export default function Lesson()
{
    const { classID, courseID, unitID, lessonID } =  useParams();
    const [ lessonDetails, setLessonDetails ] = useState();
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);
    const [ slidesSrc, setSlidesSrc ] = useState();
    const [lessonType, setLessonType ] = useState("slides");

    let navigate = useNavigate();

    useEffect(()=> {
        const fetchData = async() => {
            try {
                // Get the lesson data
                const lessonRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}/Lessons/${lessonID}`)
                const docSnap = await getDoc(lessonRef);
                const lessonData = docSnap.data();

                // Get the class data for slides if they are available
                if (Object.hasOwn(lessonData, "slides"))
                {
                    if (classID)
                    {
                        const classRef = doc(firestoreDb, `Class/${classID}`)
                        const classDoc = await getDoc(classRef);
                        const classData = classDoc.data();
                        let tempSrc = "";
                        console.log(classData);
                        if (classData) {
                            tempSrc = lessonData.slides.find(e => e.grade_level === classData.grade_level).src;
                            console.log(tempSrc);
                        }
                        
                        if (tempSrc)
                        { 
                            console.log(tempSrc);  
                            setSlidesSrc(tempSrc); 
                        } 
                        else {
                            setSlidesSrc(lessonData.slides.find(e => e.grade_level === "High School").src);
                        }
                    }
                    else {
                        setSlidesSrc(lessonData.slides.find(e => e.grade_level === "High School").src);
                    }
                }
                

                if (Object.hasOwn(lessonData, "type"))
                {
                    setLessonType(lessonData.type);
                }

                setLessonDetails(lessonData);
                setPageHeader(lessonData.name);
            } catch (error) {
                console.error(error);
            }
            
        };
        fetchData();
    }, []);
    return (
        <div className="c-page-container">
        {lessonDetails &&
        <div className="lesson-page">
            { lessonType !== "article" &&
                <LessonWithSlides lessonDetails={lessonDetails} slidesSrc={slidesSrc}/>
            }
            { lessonType === "article" &&
                <LessonArticle lessonDetails={lessonDetails} />
                
            }
            
            {/* {lessonDetails.slides &&
            <div className="slides-container">
                <EmblaCarousel slides={lessonDetails.slides}/>
                
            </div>
            } */}
            
            {/* <Link 
                to={`/courses/${courseID}/${unitID}/${lessonID}/quiz`}
                className="btn btn-primary btn-block lesson-quiz-button">
                Quiz
            </Link> */}

            <div className="lesson-quiz-button-container">
            {lessonDetails.activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/activity`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Activity
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faGamepad}
                    />
                </button>
            }
            {lessonDetails.python_activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/python_activity`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Python
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faCode}
                    />
                </button>
            }
            { !lessonDetails.activity && !lessonDetails.python_activity &&
                <button 
                onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/${lessonDetails.quiz_id}`)}
                className="c-button lesson-quiz-button">
                    <div className="lesson-quiz-button-title">
                        Up Next: Quiz
                    </div>
                    <FontAwesomeIcon 
                        className="lesson-quiz-button-icon"
                        icon={faFilePen}
                    />
                </button>
            }

            </div>

            
        </div>
        }
        </div>
    )
}