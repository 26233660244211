import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SingleMetricBox({icon, metric, title, color})
{
    return (
        <div className="analytics-single-metric-box">
            <div className="analytics-single-metric-box-header" style={{color:color}}>
                <FontAwesomeIcon
                    className="analytics-metric-box-icon"
                    icon={icon}
                />
                <p className="analytics-text">{metric}</p>
            </div>
            <p className="analytics-single-metric-box-title">{title}</p>
        </div>
    );
}