import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/ext-language_tools";
import { useEffect, useLayoutEffect, useState } from "react";
import { Terminal } from "xterm";
import 'xterm/css/xterm.css';
import { FitAddon } from "xterm-addon-fit";
import { python } from "@codemirror/lang-python";

// TOOD:
// (*) Make terminal and code editor resizable

export default function CodeEditor({editorContent, setEditorContent, setCodeOutput})
{
    const [terminal, setTerminal] = useState(null);
    const [fitAddon, setFitAddon] = useState(null);
    const [pythonWorker, setPythonWorker] = useState(null);
    const [isExecuting, setIsExecuting] = useState(false);
    const [terminalOutput, setTerminalOutput] = useState("");
    // Globals defined in the editor code
    const [pythonGlobals, setPythonGlobals] = useState(null);

    const [didSuccessfullyRun, setDidSuccessfullyRun] = useState(false);

    useEffect(() => {
        const terminalList = document.getElementsByClassName("terminal");

        // Create the terminal if it doesn't exist
        if (terminalList.length === 0)
        {
            const curTerminal = new Terminal({
                theme: {
                    background: "#191A19",
                    foreground: "#F5F2E7",
                },
                cursorBlink: true,
                cursorStyle: "block",
                convertEol: true,
            });

            const curFitAddon = new FitAddon();

            curTerminal.loadAddon(curFitAddon);
            curTerminal.open(document.getElementById("code-terminal"));
            curFitAddon.fit();

            // terminal.focus();
            setTerminal(curTerminal);
            setFitAddon(curFitAddon);

            initPyodideWebWorker(curTerminal);

            // Refit the terminal on resize
            function updateTerminal() {
                if (curFitAddon)
                {
                    curFitAddon.fit();
                    setTimeout(() => {curFitAddon.fit()}, 300);
                }
            }

            window.addEventListener('resize', updateTerminal);
            updateTerminal();
            return () => {
                window.removeEventListener('resize', updateTerminal);
            };
 
        }
    }, []);

    /* Attempts to validate the output of the terminal */
    useEffect(() => {
        if (didSuccessfullyRun)
        {
            setCodeOutput(terminalOutput, pythonGlobals);
            setDidSuccessfullyRun(false);
        }
    }, [terminalOutput])

    function initPyodideWebWorker(curTerminal)
    {
            // Create the Web Worker that will execute the Python code
            const curPythonWorker = new Worker(
                new URL("../../assets/js/python-worker.js", import.meta.url));

            // Set up the communication channel
            curPythonWorker.onmessage = (e) => {
                const { text, error, done, completeOutput, globals } = e.data;
                if (text)
                {
                    if (!text.includes("<ENDOFCODE/>"))
                    {
                        curTerminal.writeln(text);
                    }
                    
                } else if (error) {

                    curTerminal.write(error);
                    setIsExecuting(false);
                }
                else if (done) {
                    setDidSuccessfullyRun(true);
                    setTerminalOutput(completeOutput);
                    setPythonGlobals(JSON.parse(globals));
                    setIsExecuting(false);
                }

            };

            setPythonWorker(curPythonWorker);
    }

    function cancelExecute()
    {
        // Terminate the current worker
        pythonWorker.terminate();

        // Tell the user they terminated the execution
        terminal.write("Terminated execution...\n");

        // Create a new worker
        initPyodideWebWorker(terminal);

        // Reset the executing
        setIsExecuting(false);

        terminal.focus();
    }

    function onChangeEditor(updateContent)
    {
        setEditorContent(updateContent);
    }

    function clearTerminal(){
        setTerminalOutput("");
        terminal.clear();
    }

    async function executeCode()
    {;
        setIsExecuting(true);
        // Fit the terminal to the current size
        fitAddon.fit();
        terminal.focus();

        // Clear the terminal, so that it will only show the current output
        clearTerminal();

        try {
            // const editorContentModify = supplementCode(editorContent);

            // Execute the Python code found in the editor through the worker
            pythonWorker.postMessage({code: editorContent + `\nprint("<ENDOFCODE/>", locals())`});
        } catch (err) {
            // Write the error to the terminal
            console.log(err);
            setIsExecuting(false);
        }
    }

    return (
        <div className="ide-container c-page-container">
            <AceEditor
                className={"code-editor"}
                mode="python"
                theme="tomorrow_night"
                fontSize={16}
                width="100%"
                height="70%"
                value={editorContent}
                onChange={onChangeEditor}
            />
            <div className="code-terminal" id="code-terminal">
            </div>
            { !isExecuting ?
                <button 
                className="c-button code-execute-button"
                onClick={executeCode}
                disabled={isExecuting}>Run</button>
                :
                <button 
                className="c-button code-execute-button"
                onClick={cancelExecute}
                disabled={!isExecuting}>Cancel</button>
            }
            
        </div>
    );
}