import { useMemo } from 'react';
import { faPencil, faSquare, faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCodeText } from '../../assets/js/utils';


export default function ProblemDescription({title, description, instructions, codeSnippets, instructionsCompleted})
{
    // Render the lesson description
    const renderedDescription= useMemo(() => {
        return formatCodeText(description, codeSnippets);

    }, [description, codeSnippets]);

    // Renders the lesson instructions
    const renderedInstructions = useMemo(() => {
        return instructions.map((intruct) => formatCodeText(intruct, codeSnippets));
    }, [instructions, codeSnippets])

    return (
        <div className="coding-activity-description c-page-container" >
            <div className="code-lesson-description-details">
                <h3>{title}</h3>
                {renderedDescription}
            </div>
            <div className="code-instructions-container">
                <div className="code-instructions-header">
                    <FontAwesomeIcon
                        className={"code-instructions-header-icon"}
                        icon={faPencil}
                    />
                    <div className="code-instructions-header-text">Instructions</div>
                </div>
                <div className="code-instruction-item-container">
                    {renderedInstructions.map( (item, idx) => <div key={`item_${idx}`}>
                        <div>
                            <FontAwesomeIcon
                                className={`code-instruction-item-icon ${(instructionsCompleted[idx] ? ", code-instruction-item-icon-complete": "")}`}
                                icon={instructionsCompleted[idx] ? faSquareCheck : faSquare }/>
                            <span className="code-instruction-item-idx">{idx + 1}.</span>
                            {item}
                        </div>
                    </div> )} 
                </div>
            </div>
        </div>
    );
}
