import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const smallScreenPx = 992;

function ResizeBar({onMouseDownHandler, opacity})
{

    const [isHovering, setIsHovering] = useState(false);

    return (
        <span 
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onMouseDown={onMouseDownHandler}
            className="resize-bar"
            style={{opacity: opacity || isHovering}}>
            <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "white"}}/>
        </span>
    )
}
 
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}

export default function ResizableTwoPanel({PanelOne, PanelTwo, minPanelWidthPct = 0.2})
{   
    const [leftPanelWidth, setLeftPanelWidth] = useState(50);
    const [isResizing, setIsResizing] = useState(false);
    const containerRef = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    function onMouseDownHandler()
    {
        setIsResizing(true);
    }

    function onMouseUpHandler()
    {
        setIsResizing(false);
    }

    function onMouseMoveHandler(e)
    {
        if (containerRef.current && isResizing)
        {
            // Get the size of the container
            const containerWidth = containerRef.current.offsetWidth;

            // Compute the current panel size, adjusting for relative size
            const rect = e.currentTarget.getBoundingClientRect();
            let curLeftPanelSize = (e.clientX - rect.left)/containerWidth;
            
            // Clip the panel size between the minimum size
            curLeftPanelSize = Math.max(
                Math.min(curLeftPanelSize, 1.0 - minPanelWidthPct),
                minPanelWidthPct
            )

            // Normalize it from [0.0, 1.0] to [0.0, 100.0]
            curLeftPanelSize *= 100;

            setLeftPanelWidth(curLeftPanelSize);

        }
    }

    function onMouseLeaveHandler()
    {
        setIsResizing(false);
    }

    return (
        <div 
            className="resizable-dual-panel"
            ref={containerRef}
            onMouseUp={onMouseUpHandler}
            onMouseMove={onMouseMoveHandler}
            onMouseLeave={onMouseLeaveHandler}>
            
            {/* <PanelOne width={`${leftPanelWidth}%`}/> */}
            <div style={{width: windowDimensions.width > smallScreenPx ? `${leftPanelWidth - 0.25}%` : `95vw`, display: "flex", height: "100%"}}>
                {PanelOne}
            </div>
            { windowDimensions.width > smallScreenPx &&
                
                <ResizeBar onMouseDownHandler={onMouseDownHandler} opacity={isResizing | 0} />
            }
            
            <div style={{width: windowDimensions.width > smallScreenPx ? `${100 - leftPanelWidth - 0.25}%` : `95vw`, display: "flex", height: "100%"}}>
                {PanelTwo}
            </div>
            
            {/* <PanelTwo width={`${100 - leftPanelWidth}%`}/> */}
        </div>
    );
}