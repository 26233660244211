import { useMemo } from "react";
import { useTable, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

export default function GradebookTable({groupedColumns, rows, tableHeader="Grades"}) {
    const columns = useMemo(() => groupedColumns, []);
    const data = useMemo(() => rows, []);

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow
    } = useTable({
        columns,
        data,
    }, usePagination);

    const { pageIndex, pageSize } = state;

    return (
        <div className="gradebook-table-container">
            <div className="table-label">
                <h3>
                    {tableHeader}
                </h3>
            </div>

            <div className="table-container">
                <table {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map( column => (
                                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map(row => {
                                prepareRow(row);
                                return(
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map( cell => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            })
                                        }
                                    </tr>            
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="table-controls">
                <div>
                <span className="table-control-rows-per-page-container">
                        Rows per page:
                        <select className="table-control-rows-per-page" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                            {
                                [10, 25, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))
                            }
                        </select>
                    </span>
                    <span className="table-control-goto">
                        <span>
                            Page <strong>{pageIndex + 1} of {pageOptions.length} </strong>
                        </span>
                        <span>
                        | Go to page: {" "}
                        <input 
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Math.round(Math.min(Math.max(Number(e.target.value), 1), pageOptions.length)) - 1 : 0;
                                gotoPage(pageNumber)
                            }}
                            min={1}
                            max={pageOptions.length}
                        /> 
                        </span>
                    </span>
 
                    <span className="table-control-buttons">
                        <button className="table-control-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            | <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button className="table-control-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                        <button className="table-control-button" onClick={() => nextPage()} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        <button className="table-control-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <FontAwesomeIcon icon={faAngleRight} /> |
                        </button>
                    </span>
                </div>
            </div>
        </div>
    )
}
