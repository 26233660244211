export default function AILessonPlanResults({results})
{

    function createListItem(label, arr)
    {
        return (
            <div className="ai-tool-generated-item">
                <h5>{label}:</h5>
                <ul>
                {
                    arr.map((kp, idx) => <li key={`kp-li-${idx}`}>{kp}</li> )
                }
                </ul>
            </div>
        );
    }

    function createStringItem(label, val)
    {
        return (
            <div className="ai-tool-generated-item">
                <h5>{label}:</h5>
                <p className="ai-tool-generated-item-content">{val}</p>
            </div>
        );
    }

    return (
        <>
        { results &&
            <div className="c-page-container ai-result-container">
                <h3 className="ai-tool-generated-header">{results.title}</h3>
                { createStringItem("Objective", results.objective) }
                { createListItem("Key Points", results.key_points) }
                { createListItem("Introduction", results.intro) }
                { createListItem("Explain", results.explain) }
                { createListItem("Engage", results.engage) }
                { createListItem("Evaluate", results.evaluate) }
                { createListItem("Conclusion", results.conclusion) }
                { createStringItem("Homework", results.homework) }
            </div>
        }
        </>
    );
}