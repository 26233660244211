import { useEffect, useState } from "react";
import RouterComponent from './router/router';
import { updateDefaultUser } from "./assets/js/firebase-utils";
import { firebaseVerifySubscription } from "./assets/js/firebase-config";

function App() {
  const [ user, setUser ] = useState("");

  useEffect(() => {
    console.log("user", user);
    const fetchData = async() => {
      let storedUser = localStorage.getItem("user");
      if (storedUser)
      {
        storedUser = JSON.parse(storedUser);
        if (!storedUser.hasOwnProperty("user_type"))
        {
          await updateDefaultUser(); 
        }
      }
    };

    fetchData();
  });

  function handleLogout()
  {
    localStorage.setItem("user", "");
    window.location.reload(false);
    setUser(null);
  }

  async function handleUpdateUser(user)
  {
    console.log("CALLED handleUpdateUser", handleUpdateUser)
    // Figure out if the use is a student or teacher

    localStorage.setItem("user", JSON.stringify(user));
    const storedUser = await updateDefaultUser();  
    
    const verifyResult = await firebaseVerifySubscription(storedUser).then(
      (result) => {
        return result.data;
      }).catch((error) => { 
        console.error(error);
        return {
        status: 400, error_msg: "Couldn't verify your active subscription."
      };
    });

    console.log("verifyResult", verifyResult)
    
    if (verifyResult.status === 200)
    {
      const user = JSON.parse(localStorage.getItem("user"));
      const curDate = new Date();
      user.last_verified_seconds = curDate.getTime()/1000;
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
    else {
      localStorage.setItem("user", "");
      setUser(null);
    }

    return verifyResult;
  }
  
  function getUser()
  {
    return localStorage.getItem("user");
  }

  return (
    <div className="main-page">
      <RouterComponent
        handleUpdateUser={handleUpdateUser}
        user={getUser()}
        handleLogout={handleLogout}
        />
    </div>
  );
}

export default App;
