import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faSchool, faClock, faGraduationCap, faPencil } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import SingleMetricBox from "../analytics/SingleMetricBox";
import VerticalDivider from "../general/VerticalDivider";
import QuizzesPerDay from "../analytics/QuizzesPerDay";
import CircleProgressBar from "../progress_bars/CircleProgressBar";
import { firestoreDb } from '../../assets/js/firebase-config';
import { collection, query, where, getDocs } from "firebase/firestore";
import { set } from "date-fns";
import { formatGrade } from "../../assets/js/utils";
import { countDates, getRecentDateCount } from "../../assets/js/date-utils";


export default function TeacherDashboard()
{
    const [tempLabels, tempCounts] = getRecentDateCount({});
    const [totalStudents, setTotalStudents] = useState(0);
    const [totalClasses, setTotalClasses] = useState(0);
    const [quizAvg, setQuizAvg] = useState(0);
    const [totalQuizzes, setTotalQuizzes] = useState(0);
    const [quizPerDay, setQuizPerDay] = useState(tempCounts);
    const [quizPerDayLabels, setQuizPerDayLabels] = useState(tempLabels);

    useEffect(() => {
        const fetchData = async() => {
            const userData = JSON.parse(localStorage.getItem("user"));

            // Count the number of students and classes
            const classRef = collection(firestoreDb, "ClassStatistics");
            const q = query(classRef, 
                where("teacher_id", "==", userData["uid"]));

            const classQuerySnapshot = await getDocs(q);

            // Get the statistics from all the classes
            // TODO: NEED TO ONLY COUNT THE UNIQUE STUDENTS
            let totalStudentsCount = 0;
            let totalClassesCount = 0;
            let totalQuizSum = 0;
            let totalQuizzesCount = 0;
            const allQuizzesCompleted = [];
            classQuerySnapshot.forEach(doc => {
                const docData = doc.data();
                totalStudentsCount += docData.num_students;
                totalClassesCount += 1;
                totalQuizzesCount += docData.quizzes_completed;
                // Multiple by number completed to get the raw sum of quiz scores
                totalQuizSum += docData.avg_quiz_grade * docData.quizzes_completed;
                allQuizzesCompleted.push(...docData.quiz_complete_dates);
            });
            // allQuizzesCompleted.push({seconds: new Date().getTime() / 1000 - 86400 * 4});

            const [recentDates, counts] = getRecentDateCount(countDates(allQuizzesCompleted));
            const totalQuizAvg = totalQuizSum / totalQuizzesCount;

            setTotalStudents(totalStudentsCount);
            setTotalClasses(totalClassesCount);
            setQuizAvg(formatGrade(totalQuizAvg));
            setTotalQuizzes(totalQuizzesCount);
            setQuizPerDay(counts);
            setQuizPerDayLabels(recentDates);
        }
        fetchData();
    }, [])

    return (
        <div className="c-page-container">
            <h2 className="teacher-dashboard-analytics-header">Students Overview</h2>
            <div className="teacher-dashboard-student-overview">
                <SingleMetricBox icon={faUsers} metric={totalStudents} title={"Total Students"} color={"#50e991"}/>
                <VerticalDivider height={105} width={1.5}/>
                <SingleMetricBox icon={faSchool} metric={totalClasses} title={"Number of Classes"} color={"#0bb4ff"}/>  
                <VerticalDivider height={105} width={1.5} />
                <SingleMetricBox icon={faPencil} metric={totalQuizzes} title={"Quizzes Completed"} color={"#e60049"}/>  

                {/* <SingleMetricBox icon={faClock} metric={100000} title={"Time Spent Learning"} color={"#e60049"}/> */}
                {/* <VerticalDivider height={105} width={1.5} />
                <SingleMetricBox icon={faGraduationCap} metric={80} title={"Average Grade"} color={"#4f4cbf"}/> */}
            </div>
            <div className="teacher-dashboard-graphs">
                <div className="teacher-dashboard-avg-grade">
                    <CircleProgressBar 
                        progress={quizAvg}
                        textTop="Avg. Quiz Grade"
                        textBottom={`${quizAvg}%`}
                    />
                </div>
                <div className="teacher-dashboard-quizzes-per-day">
                    <QuizzesPerDay counts={quizPerDay} labels={quizPerDayLabels} />
                </div>
            </div>
        </div>
    );
}