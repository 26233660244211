import { NavLink } from "react-router-dom";
import { SidebarData, TeacherNavLinks } from "./SidebarData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRightFromBracket, faFilePen } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef } from "react";

const initFirstColWidth = "175px";
const smallScreenPx = 992;

function NavSidebarList({isMinimized})
{
    const user = JSON.parse(localStorage.getItem("user"));


    return (
        <ul className="sidenav-list">
        {SidebarData.map((val, key) => {
            return (
                    <NavLink to={val.link} key={key}>
                        <li className="sidenav-item">
                            <div className="sidenav-icon">{val.icon}</div>
                            { !isMinimized &&
                                <div className="sidenav-title">{val.title}</div>
                            }
                        </li>
                    </NavLink>

            )
        })}
        { user && user.user_type === "teacher" &&
            TeacherNavLinks.map((val, key) => {
                return (
                        <NavLink to={val.link} key={key}>
                            <li className="sidenav-item">
                                <div className="sidenav-icon">{val.icon}</div>
                                { !isMinimized &&
                                    <div className="sidenav-title">{val.title}</div>
                                }
                            </li>
                        </NavLink>

                )
            })
        }
            

        <li onClick={() => {
            localStorage.setItem("user", "");
            window.location.reload(false);
        }} className="sidenav-item sidenav-logout">
            <div className="sidenav-icon">
                <FontAwesomeIcon icon={faRightFromBracket} />
            </div>
            { !isMinimized &&
                <div className="sidenav-title">Logout</div>
            }
        </li>
        
    </ul>
    
    )
}

export default function NavSidebar()
{
    const [minimized, setMinimized] = useState(false);
    const [sidenavHeaderClass, setSidenavHeaderClass] = useState("sidenav-header");   
    const [width, setWidth] = useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
    
    function toggleMinimized(isMinimized)
    {    
        //let isMinimized = !minimized;
        if (width > smallScreenPx)
        {
            document.documentElement.style.setProperty(
                "--first-col-width",
                isMinimized ? "50px": initFirstColWidth);
    
            // Toggle minimization
            //document.querySelector("#page-content").style.marginLeft = isMinimized ? "0vw" : "3vw";
        }
        setMinimized(isMinimized);
        setSidenavHeaderClass(isMinimized ? "sidenav-header-minimized" : "sidenav-header");
    }
  
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);

    useEffect(() => {
        // Get viewport size 
        if (width <= smallScreenPx)
        {
            document.documentElement.style.setProperty(
                "--first-col-width", 0);
            // document.querySelector("#page-content").style.marginLeft = "0vw"
        }
        else {
            toggleMinimized(minimized);
        }
    }, [width]);

    return (
        <>
        { (width > smallScreenPx || !minimized) &&
        <div className="sidenav">
            <div>
                <FontAwesomeIcon 
                className={sidenavHeaderClass}
                icon={faBars}
                onClick={() => toggleMinimized(!minimized)}
                />
            </div>
            <NavSidebarList isMinimized={minimized} />
            
        </div>
        }
        { width <= smallScreenPx && minimized &&
            <div className="sidenav-minimized-icon">
                <FontAwesomeIcon 
                    className={sidenavHeaderClass}
                    icon={faBars}
                    onClick={() => toggleMinimized(!minimized)}
                />
        </div>
 
        }
        </>

    )
}