import { 
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    RouterProvider,
    Navigate
  } from "react-router-dom";
import Login from "../pages/Login";
import { Courses } from "../pages/Course/Courses";
import { CourseDetails } from "../pages/Course/CourseDetails";
import CourseError from "../pages/Course/CourseError";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/Dashboard";
import RootLayout from "../layouts/RootLayot";
import Lesson from "../pages/Course/Lesson";
import Quiz from "../pages/Course/Quiz";
import ProtectedRoute from "./ProtectedRoute";
import Unit from "../pages/Course/Unit";
import Gradebook from "../pages/Gradebook/Gradebook";
import ClassGradebook from "../pages/Gradebook/ClassGradebook";
import GameActivity from "../pages/Course/GameActivity";
import PythonActivity from "../pages/Course/PythonActivity";
import AnalyticsDashboard from "../pages/Analytics/AnalyticsDashboard";
import AIToolsDashboard from "../pages/AITools/AIToolsDashboard";
import AITool from "../pages/AITools/AITool";

export default function RouterComponent({user, handleUpdateUser, handleLogout}) {     
  
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" >
                <Route index element={user ? <Navigate to="/dashboard" /> : <Login handleLogin={handleUpdateUser}/>} />
                <Route element={
                    <ProtectedRoute isAllowed={user}>
                        <RootLayout handleLogout={handleLogout}/>
                    </ProtectedRoute>
                }>
                    <Route path="dashboard" element={<Dashboard handleLogout={handleLogout}/>} />
                    <Route path="gradebook">
                        <Route index element={<Gradebook />} />
                        <Route path=":classID" element={<ClassGradebook />} />

                    </Route>
                    <Route path="ai-tools">
                        <Route index element={<AIToolsDashboard />} />
                        <Route path=":aiTool" element={<AITool />}/>

                    </Route>
                    

                    {/* <Route path="lesson" element={<Lesson />} /> */}
                    {/* <Route path="quiz" element={<Quiz />} /> */}
    
                    <Route path="courses" errorElement={<CourseError />} >
                        <Route index element={<Courses />} />
                        <Route 
                            path=":classID/:courseID" 
                            element={<CourseDetails />} />
                        <Route 
                            path=":classID/:courseID/:unitID"
                            element={<Unit />} />
                        <Route 
                            path=":classID/:courseID/:unitID/:lessonID"
                            element={<Lesson />} />
                        <Route 
                            path=":classID/:courseID/:unitID/:lessonID/:quizID"
                            element={<Quiz />} />
                        <Route 
                            path=":classID/:courseID/:unitID/:lessonID/activity"
                            element={<GameActivity />} />
                        <Route 
                            path=":classID/:courseID/:unitID/:lessonID/python_activity"
                            element={<PythonActivity />} />
                        {/* <Route 
                            path=":classID/:courseID/:unitID/:lessonID/:quizID/quiz-results"
                            element={<QuizResults />} /> */}
                    </Route>
    
                    <Route path="admin" element={<AnalyticsDashboard />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        )
    )
    return (
        <RouterProvider router={router} />
    )
}