import { Outlet } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import NavSidebar from "../components/navbar/NavSidebar";
import { useLocation } from "react-router-dom";
import { updateDisplayPath } from "../assets/utils";
import { PageHeaderContext } from "../components/PageHeaderContext";
import { useState, useEffect } from "react";
import { firebaseVerifySubscription } from "../assets/js/firebase-config";

const SECONDS_IN_DAY = 86400;

export default function RootLayout({handleLogout}) {
    const location = updateDisplayPath(useLocation().pathname).split("/");
    let pageHeader = location[location.length - 1];

    const [curPageHeader, setPageHeader] = useState(pageHeader);
    const [ breadCrumbs, updateBreadCrumbs ] = useState("");

    async function verifySubscription(storedUser)
    {
        const verifyResult = await firebaseVerifySubscription(storedUser).then(
            (result) => {
              return result.data;
            }).catch((error) => { 
              console.error(error);
              return {
              status: 400, error_msg: "Couldn't verify your active subscription."
            };
          });
        if (verifyResult.status === 400)
        {
            handleLogout();
        }
        else {
            const curDate = new Date();
            storedUser.last_verified_seconds = curDate.getTime()/1000;
            localStorage.setItem("user", JSON.stringify(storedUser));   
        }
    }

    useEffect(()=> {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser && storedUser.user_type === "teacher") {
            const curDate = new Date();
            const secondsSinceVerified = curDate.getTime()/1000 - storedUser.last_verified_seconds;
            if (secondsSinceVerified >= SECONDS_IN_DAY)
            {
                verifySubscription(storedUser);
            }
        }
    
    });

    return (
        <PageHeaderContext.Provider value={{curPageHeader, setPageHeader}}>
            <div className="main-page-content"> 
                <NavSidebar />
                <div id="page-content">
                    <div className="page-header">
                        <h3 className="page-title">{curPageHeader}</h3>
                        <Breadcrumbs />    
                    </div>    
                    <Outlet />
                </div>
            </div>
        </PageHeaderContext.Provider>
    )
}