import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  
import { Bar } from 'react-chartjs-2';
import { generateDateArray } from "../../assets/js/date-utils";

ChartJS.register(
CategoryScale,
LinearScale,
BarElement,
Title,
Tooltip,
Legend
);

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: 'Quizzes Completed (per day)',
        font: {
          size: 20,
          // style: 'italic',
          family: 'Roboto'
        }
      },
    },
};

// const labels = generateDateArray(30);



export default function QuizzesPerDay({labels, counts}){  
  console.log(labels, counts)
  const data = {
    labels,
    datasets: [
        // {
        // label: 'Dataset 1',
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        // },
        {
        label: 'Quizzes Completed',
        data: counts,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
  };
  return <Bar options={options} data={data} />;
}