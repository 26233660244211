import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { firestoreDb, firebaseSubmitQuiz, firebaseGetAssignmentGrade, analytics } from '../../assets/js/firebase-config';
import { doc, getDoc } from "firebase/firestore";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import ErrorModal from "../../components/modals/ErrorModal";
import ProgressBar from "../../components/progress_bars/ProgressBar";
import QuizResults from "../../components/QuizResults";
import { getCurrentTimestamp, updateActivityHistory } from "../../assets/js/firebase-utils";
import { logEvent } from "firebase/analytics";
import parse from 'html-react-parser';

const AnswerButtonContent = [
    "A",
    "B",
    "C",
    "D"
]


function QuizQuestion({question}){
    return (
        <div className="quiz-question">
            {parse(question)}
        </div>
    )
}


function QuizOptions
({answers, curQuestionIdx, setCurQuestionIdx, updateAnswer, savedAnswers, numQuestions, handleSubmitQuiz, isSubmitButtonDisabled}) {
    const [selectedAnswer, setSelectedAnswer] = useState("");

    useEffect(() => {
        if (savedAnswers[curQuestionIdx] !== null)
        {
            handleClickAnswer(savedAnswers[curQuestionIdx]);
        }
    }, [curQuestionIdx]);
    
    function handleClickAnswer(answer) {
        if (selectedAnswer !== "")
        {
            const prevClickedButton = document.getElementById("quiz-answer-" + selectedAnswer);
            
            if (prevClickedButton)
            {
                prevClickedButton.classList.remove("quiz-button-selected");    
            }
        }
        
        const clickedButton = document.getElementById("quiz-answer-" + answer);
        if (clickedButton)
        {
            clickedButton.classList.add("quiz-button-selected");
            setSelectedAnswer(answer);
        }
    }

    function handleAnswerSubmit(e) {
        // Prevent from submitting form data to the current URL
        e.preventDefault();
    }


    function handleOnClickButton(idx)
    {
        if (selectedAnswer !== "")
        {
            const prevClickedButton = document.getElementById("quiz-answer-" + selectedAnswer);
            if (prevClickedButton)
            {
                prevClickedButton.classList.remove("quiz-button-selected");
            }
        }
        setCurQuestionIdx(idx);
        setSelectedAnswer("");
    }
    
    return (
        <form onSubmit={handleAnswerSubmit} className="quiz-answers">
            {
                answers.map((answer, key) => {
                    return (
                        <div id={"quiz-answer-" + key} className="quiz-answers-item" onClick={() => {
                            handleClickAnswer(key);
                            updateAnswer(curQuestionIdx, AnswerButtonContent[key]);
                        }} key={key}>
                            <label >
                                <button type="button" >
                                    {AnswerButtonContent[key]}
                                </button>
                                {answer}
                            </label>
                        </div>
                    )
                })
            }
            {curQuestionIdx !== 0 &&
            <button 
                onClick={() => handleOnClickButton(curQuestionIdx - 1)}
                type="button"
                className="c-button quiz-prev-button">
                Prev Question
            </button>
            }

            {curQuestionIdx < numQuestions - 1 &&
            <button
                onClick={() => {handleOnClickButton(curQuestionIdx + 1)}}
                type="button"
                className="c-button quiz-submit-button">
                Next Question
            </button>
            }
            
            {curQuestionIdx === numQuestions - 1 &&
            <button
                onClick={handleSubmitQuiz}
                type="submit"
                disabled={isSubmitButtonDisabled}
                id="quiz-submit-button"
                className="c-button quiz-submit-button">
                Submit Quiz
            </button>
            }
        </form>
    )
}


export default function Quiz() {
    const [curQuestionIdx, setCurQuestionIdx] = useState(0);
    // TODO: Replace with passed in questions
    const [ savedAnswers, setSavedAnswers ] = useState("");
    const [ quizData, setQuizData ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorBody, setErrorBody ] = useState("");
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [ studentGrade, setStudentGrade ] = useState("");

    const { classID, courseID, unitID, lessonID, quizID} = useParams();
    const { curPageHeader, setPageHeader } = useContext(PageHeaderContext);

    useEffect(()=> {
        const fetchData = async() => {
            try {
                const storedUser = JSON.parse(localStorage.getItem("user"));

                // Get the student grade
                const gradeRef = doc(firestoreDb, `Students/${storedUser.uid}/Class/${classID}/Assignments/${quizID}`);
                const studentGradeResult = await getDoc(gradeRef);

                if (studentGradeResult.exists())
                {
                    setStudentGrade(studentGradeResult.data());
                }

                // Get the quiz_id from the lesson
                const quizRef = doc(firestoreDb, `Courses/${courseID}/Assignments/${quizID}`)
                const quizDocSnap = await getDoc(quizRef);
                const curQuizData = quizDocSnap.data();

                setQuizData(curQuizData.quiz);
                setSavedAnswers(new Array(curQuizData.quiz.length).fill(null));
                setPageHeader(curQuizData.lesson_name + " Quiz");
            } catch (error) {
                console.error(error);
            }
            
        };
        fetchData();
    }, []);

    function updateCurQuestionIdx(idx) {
        setCurQuestionIdx(Math.max(0, idx));
    }

    function updateAnswer(idx, answer)
    {
        const updatedSavedAnswers = savedAnswers.map((a, i) => {
            if (i === idx)
            {
                return AnswerButtonContent.indexOf(answer);
            }
            else {
                return a;
            }
        });
        setSavedAnswers(updatedSavedAnswers);
    }

    async function handleSubmitQuiz() {
        setIsSubmitButtonDisabled(true);

        const studentQA = savedAnswers.map((answer, idx) => {
            return {
                "question": quizData[idx].question,
                "answer": quizData[idx].options[answer],
            }
        });

        let nullQuestionIdxs = [];

        // Get the null questions
        for(let i = 0; i < savedAnswers.length; i++)
        {
            if (savedAnswers[i] === null)
            {
                nullQuestionIdxs.push(i + 1);
            }
        }

        if (nullQuestionIdxs.length > 0)
        {
            setErrorBody(`Forgot to answer questions ${nullQuestionIdxs.join(", ")}.`)
            setIsSubmitButtonDisabled(false);
            setShowErrorModal(true);
        } else {
            const storedUser = JSON.parse(localStorage.getItem("user"));
            // Submit the quiz
            const studentGradeResult = await firebaseSubmitQuiz({
                courseID: courseID,
                quizID: quizID,
                studentQA: studentQA,
                savedAnswers: savedAnswers,
                classID: classID,
                userType: storedUser.user_type,
            }).then(result => {
                const data = result.data;
                console.log(data);
                return data;
            }).catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.error(error);
                return {
                    status: 400,
                    error_message: error.message,
                };
            });
            // Navigate to the results page
            if (studentGradeResult.status === 200)
            {
                const activity = {
                    name: `Completed ${curPageHeader}`,
                    type: "Quiz",
                    date_completed: getCurrentTimestamp(),
                    source: `/courses/${classID}/${courseID}/${unitID}/${lessonID}/${quizID}`
                };
                updateActivityHistory(activity);
                const parsedStudentGrade = JSON.parse(studentGradeResult.studentGrade);

                logEvent(analytics, "quiz_completed", {
                    grade: parsedStudentGrade.grade,
                    numQuestions: quizData.length,
                    courseID: courseID,
                    classID: classID,
                    quizID: quizID,
                });

                console.log(parsedStudentGrade);
                setStudentGrade(parsedStudentGrade);
            }
        }
    }

    return (
        <>
        {
        quizData && !studentGrade &&
        <div className="quiz c-page-container">
            <ProgressBar
                className="quiz-progress-bar"
                progress={((curQuestionIdx + 1)/savedAnswers.length) * 100}
                title={`Question ${curQuestionIdx + 1}/${savedAnswers.length}`}

            />
            <QuizQuestion question={quizData[curQuestionIdx].question}/>
            <QuizOptions
                answers={quizData[curQuestionIdx].options}
                curQuestionIdx={curQuestionIdx}
                setCurQuestionIdx={updateCurQuestionIdx}
                updateAnswer={updateAnswer}
                savedAnswers={savedAnswers}
                numQuestions={quizData.length}
                handleSubmitQuiz={handleSubmitQuiz}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
            />
        </div>
        }
        { studentGrade &&
            <QuizResults gradeData={studentGrade} />
        }
        { showErrorModal &&
            <ErrorModal 
                setOpenModal={setShowErrorModal}
                errorMsgTitle={"Please answer all the questions before submitting."}
                errorMsgBody={errorBody}
                width={500}
                height={Math.min(120 + errorBody.length/2.1, 500)}
            />
        }
    </>
    )
}