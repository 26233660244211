

export default function ProgressBar({progress, title})
{
    return (
        <div className="progress-bar-container">
            { title &&
                <div className="progress-bar-title">{title}</div>
            }
            <div className="progress-bar">
                
                <div className="progress-bar-fill" style={{width: progress + "%"}}></div>
            </div>
        </div>
    );
}