import { useEffect, useState, useContext } from "react";
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faBookOpen, faCode } from '@fortawesome/free-solid-svg-icons'
import CodeActivity from "../../components/code/CodeActivity";

export default function PythonActivity()
{
    const { classID, courseID, unitID, lessonID } = useParams();
    const { setPageHeader } = useContext(PageHeaderContext);
    const [ lessonDetails, setLessonDetails ] = useState(null);
    const [ activitiesCompleted, setActivitiesCompleted] = useState(null);
    const [ activityIdx, setActivityIdx ] = useState(0);
    const [ editorValues, setEditorValues ] = useState([]);

    let navigate = useNavigate();

    useEffect( () => {
        const fetchData = async() => { 
            try {
                const lessonRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}/Lessons/${lessonID}`);
                const docSnap = await getDoc(lessonRef);
                const lessonData = docSnap.data();
                
                setLessonDetails(lessonData);
                setPageHeader(`${lessonData.name} Python Activity`);

                // Set the initial editor values
                const initEditorValues = lessonData.python_activity.map((activity) => {
                    if (activity.editor) {
                        return activity.editor;
                    } else {
                        return "";
                    }
                });

                setEditorValues(initEditorValues);

                // Set all the initial activities completed to false
                setActivitiesCompleted(new Array(initEditorValues.length).fill(false));

            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);


    function activityCompletedCallback(activityEditorValue) {
        // Update the saved editor values
        const copyEditor = [...editorValues];
        copyEditor[activityIdx] = activityEditorValue;
        setEditorValues(copyEditor);

        // Mark the activity as completed
        const copyCompleted = [...activitiesCompleted];
        copyCompleted[activityIdx] = true;
        setActivitiesCompleted(copyCompleted);
    }

    return (
        <div className="python-activity-page">
            { lessonDetails &&
                <>
                <CodeActivity 
                    title={lessonDetails.python_activity[activityIdx].title}
                    description={lessonDetails.python_activity[activityIdx].description}
                    instructions={lessonDetails.python_activity[activityIdx].instructions}
                    codeSnippets={lessonDetails.python_activity[activityIdx].code_snippet}
                    initEditorValue={editorValues[activityIdx]}
                    answers={lessonDetails.python_activity[activityIdx].answers}
                    activityCompletedCallback={activityCompletedCallback}
                    hasCompleted={activitiesCompleted[activityIdx]}

                />


            <div className="lesson-previous-button-container">
                { activityIdx === 0 &&
                <button 
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Lesson
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faBookOpen}
                        />
                </button>
                }
                { activityIdx !== 0 &&
                 <button 
                 onClick={ () => setActivityIdx(activityIdx - 1)}
                 className="c-button lesson-quiz-button">
                     <div className="lesson-quiz-button-title">
                         Previous Problem
                     </div>
                     <FontAwesomeIcon 
                         className="lesson-quiz-button-icon"
                         icon={faCode}
                     />
                </button>
                }
                { activityIdx + 1 >= lessonDetails.python_activity.length &&
                <button 
                    disabled={!activitiesCompleted[activityIdx]}
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/${lessonDetails.quiz_id}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Up Next: Quiz
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faFilePen}
                        />
                </button>
                }
                { activityIdx + 1 < lessonDetails.python_activity.length &&
                <button 
                    disabled={!activitiesCompleted[activityIdx]}
                    onClick={ () => setActivityIdx(activityIdx + 1)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Next Problem
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faCode}
                        />
                </button>
                }
            </div>
            </>   
            }
        </div>
    );
}