import { useEffect, useState } from "react";
import Modal from "./Modal";
import { auth, firestoreDb } from '../../assets/js/firebase-config';
import { sendPasswordResetEmail } from "firebase/auth";
import SuccessMessage from "../messages/SuccessMessage";


function PasswordResetForm({setEmailSent}) {
    async function handleReset(e) {
        e.preventDefault();
        e.stopPropagation();

        const form = e.target;
        const formData = Object.fromEntries(new FormData(form).entries());
        sendPasswordResetEmail(auth, formData.email).then(() => {
            console.log(`Set reset password email to ${formData.email}`);
            setEmailSent(true);
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <div className="sign-up-form-container">
            <form className="sign-up-form" onSubmit={handleReset}>
                <div className="c-input-container">
                    <input type="text" name="email" className="c-input-text-field" />
                    <label className="c-label" htmlFor="email">Email</label>
                </div>
                <span>
                    <button className="c-button" style={{width: "200px"}} >Reset Password</button>
                </span>
            </form>
        </div>
    );
}

export default function ResetPasswordModal({setShowModal}) {
    const [modalBody, setModalBody] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    function closeModal() {
        setShowModal(false);
    }

    useEffect(() => {
        if (emailSent) {
            setModalBody(
            <div>
                <PasswordResetForm  setEmailSent={setEmailSent}/>
                <SuccessMessage msg="Reset email sent" />
            </div>
            )
        }
        else {
            setModalBody(<PasswordResetForm  setEmailSent={setEmailSent}/>)
        }
    }, [emailSent]);

    return (
        <Modal 
            title={"Reset Password"}
            body={modalBody}
            closeModal={closeModal}
        />
    )

    
}