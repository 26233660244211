
function LearningObjectives({learningOutcomes, standards})
{
    return (
        <div className="lesson-learning-objectives">
            <div>
                <h4>Learning Outcomes</h4>
                {learningOutcomes ? <ul>
                    {
                        learningOutcomes.map((outcome, key) => {
                            return(
                                <li key={key + outcome}>
                                    {outcome}
                                </li>
                            )
                        })
                    }
                </ul> : <div></div>
                }
                
            </div>
            {standards && <div><h4>Standard(s)</h4>
                 <ul>
                    {
                        standards.map((standard, key) => {
                            return (
                                <li key={key + standard}>{standard}</li>
                            )
                        })
                    }
                </ul>
                
            </div>
            }
        </div>
    )
}

function LessonDetails({summary, content}) {
    return (
        <div className="lesson-details">
            {summary &&
            <div>
                <h1>Summary</h1>
                <p>{summary}</p>
            
            </div>
            }
            {content &&
            <div>
                <h1>Content</h1>
                <p>{content}</p>    
            </div>
            }
        </div>
    )
}

export { LessonDetails, LearningObjectives };