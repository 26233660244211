import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { firestoreDb } from '../../assets/js/firebase-config';
import { doc, getDoc} from "firebase/firestore";
import { useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faBookOpen } from '@fortawesome/free-solid-svg-icons'

export default function GameActivity()
{
    const { classID, courseID, unitID, lessonID } =  useParams();
    const [lessonDetails, setLessonDetails ] = useState(null);
    const { curPageHeader, setPageHeader } = useContext(PageHeaderContext);
    let navigate = useNavigate();

    useEffect(()=> {
        const fetchData = async() => {
            try {
                const lessonRef = doc(firestoreDb, `Courses/${courseID}/Units/${unitID}/Lessons/${lessonID}`)
                const docSnap = await getDoc(lessonRef);
                const lessonData = docSnap.data();
                setLessonDetails(lessonData);
                setPageHeader(lessonData.name + " Game Activity");
            } catch (error) {
                console.error(error);
            }
            
        };
        fetchData();
    }, []);

    return (
        <div className="c-page-container">
        {lessonDetails &&
            <>
            { lessonDetails.activity &&
            <div className="unity-activity-container">
                {/* <iframe frameborder="0" src="https://itch.io/embed-upload/1936669?color=333333" allowfullscreen="" width="1280" height="740"></iframe> */}
                <iframe
                    frameBorder="0"
                    src={lessonDetails.activity.src}
                    allowFullScreen={true}
                    ><a href={lessonDetails.activity.href}>Play {lessonDetails.activity.activity_name} on itch.io</a></iframe>
            </div>
            }
            <div className="lesson-previous-button-container">
                <button 
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Lesson
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faBookOpen}
                        />
                </button>

                <button 
                    onClick={() => navigate(`/courses/${classID}/${courseID}/${unitID}/${lessonID}/${lessonDetails.quiz_id}`)}
                    className="c-button lesson-quiz-button">
                        <div className="lesson-quiz-button-title">
                            Up Next: Quiz
                        </div>
                        <FontAwesomeIcon 
                            className="lesson-quiz-button-icon"
                            icon={faFilePen}
                        />
                </button>
            </div>
            </>
        }
        </div>
    );
}