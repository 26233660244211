import { Link } from 'react-router-dom';
import RegisterClassModal from "./modals/RegisterClassModal";

export default function CourseCard({courseDetails, user})
{
    return (
        <div className="course-card">
            <div className="course-card-img">
                <img src={courseDetails.course_image} alt={`${courseDetails.course_name} image`}/>
            </div>
            <div className="course-card-title">{courseDetails.course_name}</div>
            <div className="course-card-details">{courseDetails.course_description}</div>
            <div className="course-card-button-container">
                <Link to={`${courseDetails.classID}/${courseDetails.id.toString()}`}>
                    <button className="c-button">Go to Course</button>
                </Link>
                { user.user_type === "teacher" &&
                    <RegisterClassModal courseDetails={courseDetails}/>
                }
                
            </div>
        </div>
    );
}