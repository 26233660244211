import { useState, useEffect } from "react";
import { firebaseAddStudentToCourse } from '../../assets/js/firebase-config';
import Modal  from "./Modal";
import { Oval } from "react-loader-spinner";
import ErrorMessage from "../messages/ErrorMessage";
import SuccessMessage from "../messages/SuccessMessage";
import { refreshUserData, updateActivityHistory, getCurrentTimestamp } from "../../assets/js/firebase-utils";

function CourseCodeForm({setIsValidatingCode, setValidationStatus})
{
    async function handleSubmitCode(e)
    {
        e.preventDefault();
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const form = e.target;
        const formData = Object.fromEntries(new FormData(form).entries()); 

        // Check if they put in a value for the course code
        if (!formData.courseCodeInput)
        {
            setValidationStatus({
                status: 400,
                error_msg: "Course code can't be blank.",
            });
            return; 
        }

        setIsValidatingCode(true);

        const result = await firebaseAddStudentToCourse({
            courseCode: formData.courseCodeInput,
            firstName: storedUser.userData.first_name,
            lastName: storedUser.userData.last_name,
        }).then(result => {
            const data = result.data;
            setIsValidatingCode(false);
            setValidationStatus(data);
            return data;
        }).catch(e => {
            console.error(e);
            setIsValidatingCode(false);
            return { status: 400 };
        });

        if (result.status === 200)
        {
            const course = result.enrolled_course;
            const activity = {
                name: `Enrolled in ${course.course_name}`,
                type: "Course Enrollment",
                date_completed: getCurrentTimestamp(),
                source: `/courses/${result.class_id}/${course.course_id}`,
            };
            await updateActivityHistory(activity);
            await refreshUserData();
        }
    }

    return (
        <form method="post" onSubmit={handleSubmitCode}>
            <span className="course-code-input-container">
                <div className="c-input-container">
                    <input className="c-input-text-field" name="courseCodeInput" type="text" />
                    <label htmlFor="courseCodeInput" className="c-label">Course Code</label>
                </div>
                <button className="c-button" type="submit">Enroll</button>
            </span>
        </form>
    )
}


export default function CourseCodeModal()
{
    const [openModal, setOpenModal] = useState(false);
    const [isValidatingCode, setIsValidatingCode] = useState(false);
    const [validationStatus, setValidationStatus] = useState("");

    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFooter, setModalFooter] = useState("");
    
    function closeModal() {
        setOpenModal(false);
        setValidationStatus("");
    }

    useEffect(()=> {
        // Update the modal content based on the modal state
        if (isValidatingCode)
        {
            setModalTitle("Validating Course Code...");
            setModalBody( <Oval 
                height={120}
                width={120}
                color="#5949a8"
                ariaLabel="oval-loading"
                secondaryColor="#503db3"
                strokeWidth={3}
                strokeWidthSecondary={3}
            />);
            setModalFooter("");
        }
        else {
            setModalTitle("Enroll in a New Course");
            setModalFooter("Enter in the course code provided by your instructor to enroll. If you don't know it, please reach out to your instructor.");
            
            // Check if already attempted to enroll in a course but it was unsuccessful
            if (validationStatus.status === 400)
            {
                // Failed to be added to a course
                setModalBody(
                    <div>
                        <CourseCodeForm
                            setIsValidatingCode={setIsValidatingCode}
                            setValidationStatus={setValidationStatus}
                        />
                        <ErrorMessage msg={validationStatus.error_msg}/>
                    </div>
                );
            }
            else if (validationStatus.status === 200) {
                // Successfully added to a course
                setModalBody(
                    <div>
                        <CourseCodeForm
                            setIsValidatingCode={setIsValidatingCode}
                            setValidationStatus={setValidationStatus}
                        />
                        <SuccessMessage msg={"You have been successfully added to the course. Please refresh the page to load the course."} />
                    </div>
                );
            }
            else {
                setModalBody(
                    <CourseCodeForm
                        setIsValidatingCode={setIsValidatingCode}
                        setValidationStatus={setValidationStatus}
                    />
                );
            }
            
        }
    }, [openModal, isValidatingCode, validationStatus]);

    return (
        <>
            <div className="course-code-container">
                <div className="course-code-open-button">
                    <button className="c-button" onClick={() => setOpenModal(true)}>Add a New Course</button>
                </div>
            </div>
            { openModal &&
                <Modal 
                    closeModal={closeModal}
                    title={modalTitle}
                    body={modalBody}
                    footer={modalFooter}                
                /> 
            }
        </>
    )
}
