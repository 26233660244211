/* Enable/Disable lessons for students which controls wether they can access a lesson or not. */

import { useEffect, useState } from "react";
import { firestoreDb } from "../../assets/js/firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import SuccessMessage from "../messages/SuccessMessage";
import ErrorMessage from "../messages/ErrorMessage";


function initChecks(activeLessons)
{
    const checks = [];
    for(let i = 0; i < activeLessons.length; i++)
    {
        checks.push([]);
        for (let j = 0; j < activeLessons[i].lessons.length; j++)
        {
            checks[i].push(activeLessons[i].lessons[j].enabled);
        }
    }

    return checks;
}


export default function ToggleLesson({classID})
{
    const [courseOverview, setCourseOverview] = useState();
    const [checks, setChecks] = useState({});
    const [successMsg, setSuccessMsg] = useState();
    const [errorMsg, setErrorMsg] = useState();

    async function updateEnabledLessons(e)
    {
        try {
            e.preventDefault();
            const updatedActiveLessons = [...courseOverview];
    
            // Update the class settings
            for (let i = 0; i < checks.length; i++) {
                for (let j = 0; j < checks[i].length; j++) {
                    updatedActiveLessons[i].lessons[j].enabled = checks[i][j];
                }
            }
    
            const classSettingsRef = doc(firestoreDb, `ClassSettings/${classID}`);
            await updateDoc(classSettingsRef, {
                active_lessons: updatedActiveLessons,
            });
    
            setSuccessMsg("Successfully updated the lessons.");
        } catch (error) {
            setErrorMsg("Something went wrong... Please try again later.");
        }
        
    }

    useEffect(() => {
        const fetchData = async() => {
            try {
                const classSettingsRef = doc(firestoreDb, `ClassSettings/${classID}`);
                const classSettingsDoc = await getDoc(classSettingsRef);
                const classSettingsData = classSettingsDoc.data();
                setCourseOverview(classSettingsData.active_lessons);
                setChecks(initChecks(classSettingsData.active_lessons));
            } catch(error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="toggle-class-component c-page-container">
            <h2>Open Lessons for Students</h2>
            <p>If checked, a student is allowed to view a lesson.</p>
            { courseOverview && 
                <form className="toggle-class-container" onSubmit={updateEnabledLessons}>
                    { courseOverview.map((c, unitIdx) => {
                        return (
                            <div className="toggle-unit-container" key={c.unit_id}>
                                <div >
                                    <div className="toggle-unit">
                                        <input
                                            type="checkbox"
                                            name={c.unit_id}
                                            id={c.unit_id}
                                            checked={checks[unitIdx].every(check => check)}
                                            onChange={(e) => {
                                                const updatedChecks = [...checks]; 
                                                
                                                updatedChecks[unitIdx] = checks[unitIdx].map((check) => {
                                                    return e.target.checked;
                                                });
                                                setChecks(updatedChecks);
                                                setSuccessMsg("");
                                            }}
                                        />
                                        <label htmlFor={c.unit_id} > {`Unit ${unitIdx + 1}: ${c.unit_name}`}</label>
                                    </div>
                                </div>
                                <div className="toggle-lesson-container">
                                    {c.lessons.map((l, lessonIdx) => {
                                        return (
                                            <div className="toggle-lesson" key={l.lesson_id}>
                                                <input type="checkbox" name={l.lesson_id} id={l.lesson_id}  checked={checks[unitIdx][lessonIdx]}
                                                    onChange={() => {
                                                        const updatedChecks = [...checks];
                                                        updatedChecks[unitIdx][lessonIdx] = !updatedChecks[unitIdx][lessonIdx];
                                                        setChecks(updatedChecks);
                                                        setSuccessMsg("");
                                                    }}/>
                                                <label htmlFor={l.lesson_id} > {`${lessonIdx + 1}. ${l.lesson_name}`}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    <button className="c-button">Submit</button>
                </form>
            }
            {successMsg &&
                <SuccessMessage msg={successMsg} />
            }
            {errorMsg &&
                <ErrorMessage msg={errorMsg} />
            }
        </div>
    );
}
