import { useEffect, useState, useContext } from 'react';
// Import Components
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { firestoreDb } from '../../assets/js/firebase-config';
import { collection, getDocs, getDoc, doc, query, where, documentId} from "firebase/firestore";
import { coursesCollection } from '../../constants';
import { PageHeaderContext } from "../../components/PageHeaderContext";
import CourseCodeModal from "../../components/modals/CourseCodeModal";
import RegisterClassModal from "../../components/modals/RegisterClassModal";
import CourseCard from "../../components/CourseCard";

function CoursePanel({courseDetails, user})
{
    return (
        <Card className="shadow-sm bg-white rounded mb-4">
            <Card.Img variant="top" className="img-fluid" src={courseDetails.course_image}/>
            {/* <img src={Grow} className="rounded"/> */}
            <Card.Body className="d-flex flex-column">
                <div className="d-flex mb-2 justify-content-between">
                    <Card.Title className="mb-0 font-weight-bold">{courseDetails.course_name}</Card.Title>
                </div>
                <Card.Text className="text-secondary" style={{"fontSize": 12}}>
                    {courseDetails.course_description}
                </Card.Text>
                <div className="course-card-button-container">
                    <Link to={`${courseDetails.classID}/${courseDetails.id.toString()}`}>
                        <button className="c-button">Go to Course</button>
                    </Link>
                    { user.user_type === "teacher" &&
                        <RegisterClassModal courseDetails={courseDetails}/>
                    }
                    
                </div>
            </Card.Body>
        </Card>
    );
}

export function Courses()
{
    const [courses, setCourses] = useState([]);
    const {curPageHeader, setPageHeader} = useContext(PageHeaderContext);
    const [storedUser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));

    function clipDescription(filteredData)
    {
        for (let i = 0; i < filteredData.length; i++)
        {
            if (filteredData[i].course_description.length > 600)
            {
                filteredData[i].course_description = filteredData[i].course_description.substring(0, 600);
                filteredData[i].course_description += "...";
            }
        }
    }

    useEffect(() => {
        const fetchData = async() => {
            try {
                // Get user data
                const userData = storedUser.userData;

                 // Get the active courseIds 
                 const activeCoursesIds = userData.active_classes.map(c => c.course_id);
                 let courseIdToClassId = {};
                 if (activeCoursesIds.length > 0)
                 {
                     // Map the courseIds to classIds                     
                     for (let i = 0; i <  userData.active_classes.length; i++)
                     {
                         const activeCourse = userData.active_classes[i];
                         courseIdToClassId[activeCourse.course_id] = activeCourse.class_id;
                     }
                }

                if (storedUser.user_type === "student")
                {   
                    if (activeCoursesIds.length > 0)
                    {
                        // Get the details about the courses the user is actively enrolled in
                        const courseCollection = query(
                            collection(firestoreDb, coursesCollection),
                            where(documentId(), "in", activeCoursesIds),
                        );
                        const data = await getDocs(courseCollection);
                        const filteredData = data.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                            classID: courseIdToClassId[doc.id],
                        }));
                        clipDescription(filteredData);
                        setCourses(filteredData);
                    }
                }
                else if (storedUser.user_type === "teacher")
                {
                    const coursesRef = collection(firestoreDb, coursesCollection);
                    const data = await getDocs(coursesRef);
                    const filteredData = data.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                        classID: activeCoursesIds.length > 0 ? courseIdToClassId[doc.id] : null,
                    }));
                    clipDescription(filteredData);



                    // const tempCourses = [];
                    // for (let i = 0; i < 9; i++)
                    // {
                    //     const curFilteredData = JSON.parse(JSON.stringify(filteredData[0]));
                    //     curFilteredData.course_description = `${i}: ${curFilteredData.course_description}`;
                    //     tempCourses.push(curFilteredData);
                        
                    // }
                    setCourses(filteredData);
                }
            } catch (err)
            {
                console.error(err);
            }
            
        };
        fetchData();
        setPageHeader("Courses");
    }, []);
    return (
        <>

            <div className="course-cards-container">
                { 
                    courses.map(data => (
                        <CourseCard key={data.id} courseDetails={data} user={storedUser} />   
                    ))
                }
            </div>

                { storedUser.user_type === "student" &&
                    <CourseCodeModal />
                }

        </>
    );
}
