import { firestoreDb } from './firebase-config';
import { doc, getDoc, Timestamp, updateDoc, addDoc, collection } from "firebase/firestore";
import { maxRecentActivities } from './firebase-constants';

async function refreshUserData()
{
    console.log("REFRESHING USER DATA");
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.user_type === "student")
    {
        console.log("SETTING USER DATA");
        const studentRef = doc(firestoreDb, `Students/${user.uid}`);
        const studentDoc = await getDoc(studentRef);
        const studentData = studentDoc.data();
        user.userData = studentData;
        localStorage.setItem("user", JSON.stringify(user));
    }
    else if (user.user_type === "teacher")
    {
        const teacherRef = doc(firestoreDb, `Teachers/${user.uid}`);
        const teacherDoc = await getDoc(teacherRef);
        const teacherData = teacherDoc.data();
        user.userData = teacherData;
        localStorage.setItem("user", JSON.stringify(user));
    }
}

async function updateDefaultUser()
{
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const userRef = doc(firestoreDb, `Users/${storedUser.uid}`);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    storedUser.user_type = userData.user_type;
    storedUser.activity_history = userData.recent_activity_history;
    localStorage.setItem("user", JSON.stringify(storedUser))

    // Get the data relating to student/teacher
    await refreshUserData();

    return storedUser;
}


function getCurrentTimestamp()
{
    return Timestamp.fromDate(new Date());
}

async function updateActivityHistory(activity)
{
    const storedUser = JSON.parse(localStorage.getItem("user"));

    const activityRef = collection(firestoreDb, "Users", storedUser.uid, "Activities");
    await addDoc(activityRef, activity);

    // Create a new activity
    const activityHistory = storedUser.activity_history;
    activityHistory.push(activity);

    if (activityHistory.length > maxRecentActivities) {
        // Remove the last element
        activityHistory.shift();
    }

    localStorage.setItem("user", JSON.stringify(storedUser))

    const studentRef = doc(firestoreDb, `Users/${storedUser.uid}`);
    await updateDoc(studentRef, {
        "recent_activity_history": activityHistory,
    });
}

export {
    refreshUserData,
    updateDefaultUser,
    getCurrentTimestamp,
    updateActivityHistory
};