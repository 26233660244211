import { useEffect, useState, useRef } from "react";

// function interpolateColor(color1, color2, factor) {
//     // Parse hex colors into RGB components
//     const r1 = parseInt(color1.substr(1, 2), 16);
//     const g1 = parseInt(color1.substr(3, 2), 16);
//     const b1 = parseInt(color1.substr(5, 2), 16);
  
//     const r2 = parseInt(color2.substr(1, 2), 16);
//     const g2 = parseInt(color2.substr(3, 2), 16);
//     const b2 = parseInt(color2.substr(5, 2), 16);
  
//     // Interpolate RGB components
//     const r = Math.round(r1 + factor * (r2 - r1));
//     const g = Math.round(g1 + factor * (g2 - g1));
//     const b = Math.round(b1 + factor * (b2 - b1));
  
//     // Convert RGB back to hex
//     const hex = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
//     console.log(hex);
//     return hex;
//   }

function interpolateHSLColor(hsl1, hsl2, factor) {
    const h1 = hsl1[0], s1 = hsl1[1], l1 = hsl1[2];
    const h2 = hsl2[0], s2 = hsl2[1], l2 = hsl2[2];
  
    // Interpolate HSL components
    const h = h1 + factor * (h2 - h1);
    const s = s1 + factor * (s2 - s1);
    const l = l1 + factor * (l2 - l1);
  
    return `hsl(${h}, ${s}%, ${l}%)`;
  }


// How fast to increase the value each timstep 
const incUnit = 0.1;

export default function CircleProgressBar({
    progress,
    textTop,
    textBottom,
    initialHSLColor=[0, 100, 60],
    finalHSLColor=[104, 85, 60],
    animDelay=2})
{
    const [curProgress, setCurProgress] = useState(0);
    // const delayTime = useRef(totalAnimTime / (progress/incUnit));
    useEffect(() => {
        if (curProgress < progress)
        {
            const interval = setInterval(() => {
                setCurProgress(curProgress + incUnit);
            }, animDelay);
            return () => clearInterval(interval);
        }

    }, [curProgress, progress]);
    return (
        <div className="circle-progress-bar-container">
            <div className="circle-progress-bar" style={{
                "background": `radial-gradient(closest-side, white 79%, transparent 80% 100%), 
                                conic-gradient(${interpolateHSLColor(initialHSLColor, finalHSLColor, curProgress/100)} ${curProgress}%, var(--progress-bar-background) 0)`}} >
                { (textTop || textBottom) &&
                <div className="circle-progress-bar-text">
                    <div>{textTop}</div>
                    <div>{textBottom}</div>
                </div>
                }
            </div>
        </div>

    );
}
