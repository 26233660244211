/* Show history of activities completed */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
/* Info needed for an activity 
    name
    date-completed
    type
*/

function ActivityInfo({activity})
{
    const navigation = useNavigate();
    function getFormattedDate()
    {
        const dateCompleted = new Date(activity.date_completed.seconds * 1000);
        return dateCompleted.toLocaleString(
            "en-US", {month: "short", day: "2-digit", year: "numeric"});
    }

    return (
        <div className="activity-info-container">
            <div className="activity-info-details">
                <div className="activity-info-title">{activity.name}</div>
                <div className="activity-info-date">
                    <FontAwesomeIcon icon={faCalendar} />
                    {getFormattedDate()}
                </div>
            </div>
            { activity.hasOwnProperty("source") &&
            <div className="activity-info-goto">
                <button className="c-button" onClick={() => {navigation(activity.source)}}>Go</button>
            </div>
            }

        </div>
    );
}


export default function ActivityHistory({activities, title})
{
    function sortActivities(act)
    {
        act.sort((a, b) => {
            return b.date_completed.seconds - a.date_completed.seconds;
        });    
        return act;
    }

    return (
        <div className="c-page-container activity-history-container">
            <h4 className="activity-history-header">
                {title}
            </h4>
            <div className="activity-history-activities">
                { (activities && activities.length > 0) ?
                    sortActivities(activities).map((activity, key) => {                    
                        return <ActivityInfo key={key} activity={activity} />
                    })
                    :
                    <div className="activity-no-info">No history to show...</div>
                }
            </div>
        </div>
    );
}