
import CircleProgressBar from "./progress_bars/CircleProgressBar";
import { formatGrade } from "../assets/js/utils";

export default function QuizResults({gradeData}) {
    // const { setPageHeader } = useContext(PageHeaderContext);
    // const { classID, courseID, unitID, lessonID, quizID } = useParams();
    // const [ gradeData, setGradeData ] = useState("");

    // useEffect(() => {
    //     const fetchData = async() => {
    //         // Get the student grade
    //         const assignData = await firebaseGetAssignmentGrade({
    //             assignID: quizID,
    //             classID: classID,
    //         }).then(result => {
    //             console.log(result);
    //             return result.data;
    //         }).catch(error => {
    //             console.error(error);
    //             return {
    //                 status: 400,
    //                 error_message: error.message,
    //             };
    //         });
    //         if (assignData.status === 200)
    //         {
    //             const grade = JSON.parse(assignData.studentGrade)
    //             setGradeData(grade)
    //         }
            
    //     };
    //     setPageHeader("Quiz Results");
    //     fetchData();

    // }, []);

    return (
        <div className="quiz-results-page">
            <div className="quiz-results-container c-page-container">
                { gradeData &&
                    <>
                        <h1 className="quiz-results-header">You received a grade of {formatGrade(gradeData.grade)}%</h1>
                        
                        <CircleProgressBar
                            progress={gradeData.grade}
                            textTop={"You Scored"}
                            textBottom={`${gradeData.num_correct}/${gradeData.answers.length}`}
                        />
                    </>        
                }
                    {/* <CircleProgressBar progress={50} textTop={"You Scored"} textBottom={"10/10"}/> */}        
            </div>
        </div>
    )
}