import CodeActivity from "../../code/CodeActivity";

export default function AIPythonActivityResults({results})
{
    function createActivity(results) {
        console.log(results);
        return results;
    }

    return (
        <>
            { results &&
                 <div className="python-activity-page">
                    <CodeActivity 
                        title={results.title}
                        description={results.description}
                        instructions={results.instructions}
                        codeSnippets={results.code_snippet}
                        initEditorValue={results.editor}
                        answers={results.answers}
                        activityCompletedCallback={()=>{}}
                        hasCompleted={false}
                    />
                </div>
            }
        </>
    );
}