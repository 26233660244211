import GradebookTable from "../../components/table/GradebookTable";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { PageHeaderContext } from "../../components/PageHeaderContext";


export default function Gradebook()
{
    const [activeClasses, setActiveClasses] = useState();
    const { setPageHeader} = useContext(PageHeaderContext);


    // First page will show the what classes the teacher is assigned to
    // Next page will show the gradebook for a particular class
    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const curActiveClasses = user.userData.active_classes;
            setActiveClasses(curActiveClasses);
        }
        catch (error)
        {
            console.log(error);
        }
        setPageHeader("Gradebook");
    }, [])
    return (
        <div className="gradebook-root-page c-page-container">
            <h1>Classes</h1>
            <div className="table-container">
                { activeClasses ?
                <table>
                    <thead>
                        <tr>
                            <th>Course Name</th>
                            <th>Class Label</th>
                            <th>Grade Level</th>
                            <th>Course Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        activeClasses.map(c => (
                            <tr key={c.course_code} className="gradebook-root-class-item">
                                <td>
                                    <Link to={`/gradebook/${c.class_id}`}>
                                        {c.course_name}
                                        {/* {`${c.course_name} for ${c.class_label} in ${c.grade_level}`} */}
                                    </Link>
                                </td>
                                <td>{c.class_label}</td>
                                <td>{c.grade_level}</td>
                                <td>{c.course_code}</td>
                            </tr>
                        ))
                    }
                    
                    </tbody>
                </table>
                :
                <p>You haven't registered any classes ...</p>
                }
            </div>
            {/* <GradebookTable /> */}
        </div>
    );
}
