import { useLocation, Link } from "react-router-dom";
import { updateDisplayPath } from "../assets/utils";

export default function Breadcrumbs() {
    const location = useLocation();
    let currentLink = "";
    
    // All non-empty crumbs
    let crumbs = location.pathname.split("/")
        .filter(crumb => crumb !== '');
    let displayPath = updateDisplayPath(crumbs.join("/")).split("/");

    const crumbLinks = [];
    for (let i = 0; i < crumbs.length; i++) {
        currentLink += `/${crumbs[i]}`;
        if (crumbs[0] === "courses") {
            if (i !== 1)
            {
                crumbLinks.push(
                    (
                        <li className="breadcrumb-item" key={crumbs[i]}>
                            <Link to={currentLink}>{displayPath[i]}</Link>
                        </li>
                    )
                );
            }
        }
        else {
            crumbLinks.push(
                (
                    <li className="breadcrumb-item" key={crumbs[i]}>
                        <Link to={currentLink}>{displayPath[i]}</Link>
                    </li>
                )
            );
        }
    }
    // crumbs = crumbs.map((crumb, idx) => {
    //         currentLink += `/${crumb}`;
    //         return (
    //             <li className="breadcrumb-item" key={crumb}>
    //                 <Link to={currentLink}>{displayPath[idx]}</Link>
    //             </li>
    //         );
    //     });

    return (
        <ul className="breadcrumb">
            {crumbLinks}
        </ul>
    )
}